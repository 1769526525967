<template>
  <div
    class="side-bar-area aside-bottom-padding"
    style="border-radius: 10px;height: 75vh;"
  >
    <!-- {{ testdata }} -->
    <div class="">
      <div style="search">
        <div >
          <doctype-link class="bg-white m-1"   doctype="Healthcare Service Unit" @selected="onSelectedServiceUnit" :filters="filters" ></doctype-link>
          <div
            ref="service-unit-search"
            style="width: 100%; display: flex; justify-content: space-between"
            class="ref-field-input"
          />
        </div>
        <!-- <div>
          <div
            style="width: 400px; display: flex; justify-content: space-between"
            ref="patient-search"
            class="ref-field-input"
          />
        </div> -->
      </div>
    </div>
    <!-- <div class="card">
      <div class="card-body" style="width:100%"><div ref="doctype" class="ref-field-input" /></div>
    </div> -->
    <!-- {{ patientList }} -->
    <b-row>
      <b-col cols="12">
        <b-list-group class="scroll-list">
          <patient-item
            v-for="(patient, index) in patientList"
            :key="patient.name"
            :patient="patient"
            :index="index"
            :active-patient="activePatient"
            :full-screen-work-area="fullScreenWorkArea"
            :status="patient.status"
            :is-active="isActive"
            :current-page="currentPage"
            @patient-selected="patientClick"
          >{{ activePatient.name }} {{
            patient.name }}</patient-item>
          <div
            v-if="patientList.length < 1"
            id="contain"
          >

            <b>
              <h style="font-weight: 600;">Oops!...Could not find the searched patient(s)</h>
            </b>
          </div>
        </b-list-group>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { getPatientsPerServiceUnit } from './service'

import PatientItem from './PatientItem.vue'

export default {
  name: 'SideBar',
  components: {
    PatientItem,
  },
  data() {
    return {
      doctype: 'Healthcare Service Unit',
      patientList: [],
      activePatient: {},
      filters: {
            is_group: 1,
            inpatient_occupancy: 1,
          }
    }
  },
  mounted() {
    this.makeServiceUnitSearchControl()
    // this.makeSelectDoctypeControl()
  },
  methods: {
    onSelectedServiceUnit(value){
          if (value) {
            
              this.$emit('serviceUnitSelected', value)
              this.fetchPatientWithMedications(value)
            }

    },
    patientClick(patient) {
      this.activePatient = patient
      this.$emit('patient-selected', patient)
    },
    fetchPatientWithMedications(serviceUnit) {
      getPatientsPerServiceUnit({ servicePoint: '', serviceUnit }).then(data => {
        // console.log(data);
        this.patientList = data.inpatients || []
        // this.loading = false;
      })
    },
    makeServiceUnitSearchControl() {
      const parent = this
      const customer_field = frappe.ui.form.make_control({
        df: {
          label: 'Service Unit',
          fieldtype: 'Link',
          fieldname: 'service_unit',
          options: 'Healthcare Service Unit',
          placeholder: ('Search Service Unit'),
          filters: {
            is_group: 1,
            inpatient_occupancy: 1,
          },
          onchange() {
            if (this.value) {
              // alert("su updated " + this.value);
              parent.$emit('serviceUnitSelected', this.value)
              parent.fetchPatientWithMedications(this.value)
            }
            // parent.filters.service_unit = this.value;
            // parent.loading = true;
            // getPatientPrescriptionsV2({
            //   fetch: "inpatients",
            //   ...parent.filters,
            //   service_unit: this.value,
            // }).then((data) => {
            //   parent.prescription = data;
            //   parent.loading = false;
            // });
            // parent.getMatchedPrescriptions(parent.filters);
          },
        },
        parent: parent.$refs['service-unit-search'],
        render_input: true,
      })
      customer_field.toggle_label(false)
      $('#modal-body')
        .find('.input-max-width')
        .removeClass('input-max-width')
    },
  },
}
</script>
<style scoped>

.scroll-list{
height: 72vh;
overflow-y: scroll;
overflow-x: hidden;
scrollbar-width:thin;
background: white;
}
.search-bar {
  flex: 1;
  max-width: 3000px;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 8px 1.25rem;
  background-color: #fff;
}

.list-group-item:hover {
  background: lightgray;
  cursor: pointer;
}

.list-group-item-secondary {
  /* -webkit-box-shadow: -3px 0px 3px 4px rgba(27, 21, 76, 0.7);
  box-shadow: -3px 0px 3px 4px rgba(27, 21, 76, 0.7); */
  border-left: 5px solid #7c2112;
}

.list-group-item-secondary {
  background: #1f19192b;
}

.b-avatar {
  font-size: inherit;
  font-weight: 400;
  line-height: 1;
  text-align: center;
  background: white;
  color: black;
  border: 1px solid darkgray;
}

.fixed-header {
  width: 100%;
  position: fixed;
  top: 400px;
}

.fixed-header {
  top: 0;
}

.active {
  color: black;
  /* border-left: 5px solid #2490ef;
    border-right: 5px solid #2490ef; */
  background-color: lightgray;
  border-left: 5px solid #06a79e;
  border-top: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
}

.tb-row {
  cursor: pointer;
}

body {
  font-family: sans-serif;
  font-size: 16px;
  color: #fff;
}

ul.sidebar-menu {
  margin: 0;
  padding: 0;
  max-width: 450px;
  list-style: none;
  list-style-type: none;
}

.sidebar-menu li a span {
  margin-right: 20px;
  color: #fff;
}

.sidebar-menu li a {
  background-color: #d7dde2;
  padding: 20px 25px;
  display: block;
  text-decoration: none;
  color: #fff;
}

.sidebar-menu li a:hover {
  background-color: #d7dde2;
  padding: 20px 25px;
  display: block;
  text-decoration: none;
  color: #fff;
}

li.have-children ul {
  padding: 0px;
}

li.have-children ul li a {
  background-color: #d7dde2;
  padding-left: 62px;
}

li.have-children ul li a:hover {
  color: #fff;
  background-color: #d7dde2;
  padding-left: 62px;
}

li.have-children,
li {
  position: relative;
}

.have-children span::after {
  position: absolute;
  right: 30px;
  content: "\f054";
  color: #fff;
  transition: all 0.5s;
}

li.active.have-children span::after {
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.sidebar-menu .have-children>ul {
  display: none;
}

.no-sides {
  margin-left: 0px;
  margin-right: 8px;
}

.side-bar-area {
    background: rgba(211, 211, 211, 0.423);
    width: 100% !important;
    padding-top: 20px;
    min-height: 400px;
   
    overflow-y: hidden;
    overflow-x: hidden;

    padding-left: 11px !important;
    padding-right: 11px !important;
}

.name-header {
  background: #b1d0eb;
  color: white;
  padding: 14px;
  border-radius: 0px 16px 16px 0px;
  width: 95%;
  font-weight: 800;
}

.search-input {
  margin-top: 5%;
}

.no-results {
  margin-top: 5%;
  padding-left: 7%;
}

.rounded {
  border-radius: 12px;
}

.top-margin-space {
  padding-bottom: 8px;
  height: 85vh;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: thin;
  background: white;
  position: absolute;
}

.no-border {
  border: 0px solid black !important;
}

.form-control {
  padding: 0.438rem 1rem;
  background-color: #fff;
  background-clip: padding-box;
  border: 0px solid #d8d6de;
  border-radius: 0.357rem;
  width: 100%;
  font-weight: 400;
  line-height: 1.45;
  font-size: 13px;
  color: #6e6b7b;
  -webkit-transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}

.btn-custom {
  height: 36px;
}

.menu-options {
  margin-left: 0px;
  font-size: 18px;
  margin-top: 12px;
  width: 100%;
}

.closed {
  padding-right: 0px !important;
  padding-left: 6px !important;
}

.psm {
  display: inline-block;
  border-radius: 50px;
  box-shadow: 0px 0px 2px #888;
  height: 40px;
  width: 40px;
  text-align: center;
  position: relative;
  padding-bottom: 10px;
  position: relative;
  margin-top: 5px;
}

#ico {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 60%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
  display: block;
  color: black;
  font-weight: 700px;
}

.content-area {
  height: 74vh;
  overflow-y: hidden;
  overflow-x: hidden;
  scrollbar-width: thin;
  background: white;
  padding-bottom: 16px;
  padding-left: -5px;
  border-radius: 5px;
  margin-top: 10px;
  position: relative;
}
</style>
