<template>
  <div>
    <b-row>
      <h5 class="mt-3">
        Warehouse
      </h5>
    </b-row>
    <b-row style="width: 107%">
      <user-warehouse
        style="width: 100% !important "
        @warehouse="setWarehouse"
      />
    </b-row>
    <b-row>
      <h5 class="mt-3">
        Customer
      </h5>
    </b-row>
    <b-row style="width: 100%">
      <b-form-select
        v-model="selected_payment_mode"
        style="width: 100%"
        :options="payment_mode_options"
        value-field="payment_mode"
        text-field="payment_mode"
        @change="onPaymentModeChangeCreated($event)"
      />
    </b-row>

    <b-row
      style="width: 100%"
      class="mt-4"
    >
      <b-button
        style="width: 100%"
        variant="success"
        @click="createSheet()"
      >
        <b>CREATE</b>
      </b-button>
    </b-row>
  </div>
</template>
<script>
import UserWarehouse from '@/views/erp/billing/components/chargesheet/components/UserWarehouse.vue'
import {

  api,
} from '../../service'

export default {
  name: 'PatientItem',
  components: {
    UserWarehouse,
  },
  props: {
    patient: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      warehouse: null,
      selected_payment_mode: null,
      payment_mode_options: [],
    }
  },
  created() {
    this.get_payment_mode_options()
  },
  methods: {
    createSheet() {
      const chargeSheet = {
        patient: this.patient,
        encounter: '',
        warehouse: this.warehouse,
        is_inpatient_bill: 1,
        customer: this.selected_payment_mode_customer,
        is_private_wing: this.wing == this.Private_Wing_String ? 1 : 0,
        doctype: 'Charge Sheet',
        needs_approval: 0,
      }
    },
    get_payment_mode_options() {
      // clinical.api.tests.update_items_pw.get_admission_status
      const parent = this
      api({
        method:
                    'billing.billing.api.charge_sheet.actions.create_charge_sheet.patient_payment_modes',
        args: {
          patient: this.patient,
        },
        freeze: false,
      }).then(r => {
        console.log('Payment options are: ')
        console.log(r)
        parent.payment_mode_options = r
        // this.get_scheme_status();
      })
    },
    setWarehouse(wh) {
      this.warehouse = wh
    },
  },
}
</script>
