<template>
  <div class="root">
    <div class="">

      <h1>What</h1>
      <div style="search">
        <div>
          <doctype-link doctype="Healthcare Service Unit" ></doctype-link>
          <div
            ref="appointment-search"
            style="width: 400px; display: flex; justify-content: space-between;"
            class="ref-field-input"
          />
        </div>
        <div>
          <div
            ref="patient-search"
            style="width: 400px; display: flex; justify-content: space-between;"
            class="ref-field-input"
          />
        </div>
      </div>
    </div>
    <b-modal
      id="modal-prevent-closing"
      ref="changeServiceUnitModal"
      title="Select Pharmacy Warehouse"
      @hidden="resetServiceUnits"
      @ok="updateWarehouse"
    >
      <b-form-select
        v-model="warehouse"
        :options="warehouses"
        placeholder="Select warehouse"
      />
      <div class="mt-3">
        Selected: <strong>{{ warehouse }}</strong>
      </div>
    </b-modal>
    <!-- <div v-if="loading" style="padding-top:25%; text-align:center; height:100vh;">
                                                                                <b-spinner style="width: 300px; height: 300px;margin:auto;"></b-spinner>
                                                                            </div> -->

    <div style="padding-top: 0.2%; margin-top: 8px">
      <b-row>
        <b-col>
          <div style="display: flex; justify-content: flex-end">
            <b-button-group style="margin-right: 16px; margin-top: 4px">
              <!-- <b-button
                style="margin-right: 2px"
                variant="primary"
                @click="postInvoice"
                >Create Sales Order</b-button
              > -->
              <!-- <b-button
                v-if="prescription && prescription.length"
                style="margin-right: 2px"
                variant="primary"
                @click="printPrescription(prescription[0])"
                >Print</b-button
              > -->
              <!-- <b-button style="margin-right:2px;" variant="primary" @click="checkInvoice">Payment </b-button> -->
              <b-button
                v-if="paidPres.length > 0"
                style="margin-right: 2px"
                variant="primary"
              >Dispense</b-button>
              <!-- <b-button style="margin-right:2px;" variant="primary" @click="transferPrescription">Transfer</b-button> -->
            </b-button-group>
          </div>
        </b-col>
      </b-row>
      <b-spinner
        v-if="loading"
        label="Spinning"
      />
      <div class="table-responsive">
        <b-table
          :fields="fields"
          class="table"
          stacked="md"
          :per-page="10"
          :current-page="currentPage"
          small
          hover
          :items="prescription"
        >
          <template #cell(action)="row">
            <div style="display: flex">
              <b-button
                class="sm"
                variant="primary"
                @click="openModal(row.item)"
              >
                View</b-button>
              <!-- <b-button
                style="margin-left: 8px"
                @click="addAMatch()"
                variant="primary"
                >MatchStock Item</b-button -->
              <b-button
                style="margin-left: 8px"
                variant="primary"
                @click="openEditModal(row.item)"
              >
                Edit Prescription
              </b-button>
              <b-button
                style="margin-left: 8px"
                variant="danger"
                @click="cancel(row.item)"
              >
                Cancel Prescription
              </b-button>
              <b-button
                style="margin-left: 8px"
                variant="primary"
                @click="splitPrescription(row.item)"
              >Duplicate</b-button>
            </div>
          </template>
        </b-table>
        <b-pagination
          v-model="currentPage"
          :total-rows="prescription.length"
          :per-page="10"
          aria-controls="my-table"
        />
        <b-modal
          id="modal-1"
          ref="viewPrescription"
          size="xl"
          :ok-title="
            activeRow.patient_appointment
              ? 'Create Sales Order'
              : 'Create Medication Order'
          "
          :ok-disabled="activeRow.docstatus === 0"
          title="Doctor's Drug Prescription"
          @ok="createMedicationOrder()"
        >
          <div style="margin-top: 16px">
            <strong>Patient Status:</strong>
            <span style="margin-bottom: 0">{{
              activeRow.inpatient_record ? "Inpatient" : "Outpatient"
            }}</span>
          </div>
          <div style="display: flex">
            <div style="margin-top: 16px; width: 50%; flex: 1">
              <strong>Drug:</strong>
              <pre style="margin-bottom: 0">{{ activeRow.drug }}</pre>
            </div>
            <div style="margin-top: 16px; width: 50%; flex: 1">
              <strong>Dose:</strong>
              <pre style="margin-bottom: 0">{{ activeRow.dose }}</pre>
            </div>
          </div>
          <div style="display: flex">
            <div style="margin-top: 16px; width: 50%; flex: 1">
              <strong>Route of Administration:</strong>
              <pre style="margin-bottom: 0">{{
                activeRow.route_of_administration
              }}</pre>
            </div>
            <div style="margin-top: 16px; width: 50%; flex: 1">
              <strong>Start Date:</strong>
              <pre style="margin-bottom: 0">{{ activeRow.start_date }}</pre>
            </div>
          </div>
          <div style="margin-top: 16px">
            <strong>Instructions:</strong>
            <pre style="margin-bottom: 0">{{ activeRow.instructions }}</pre>
          </div>
          <div style="margin-top: 16px">
            <b-button
              v-b-toggle.collapse-1
              variant="primary"
            >Ongoing Medication Orders</b-button>
            <b-collapse
              id="collapse-1"
              class="mt-2"
            >
              <b-table
                :fields="imoFields"
                :items="imoItems"
              />
            </b-collapse>
          </div>
          <div v-if="activeRow.docstatus === 0">
            <span style="color: red">
              The ordering ppractitioner has not signed off this prescription. You will not be allowed to proceed
            </span>
          </div>
          <div style="margin-top: 16px">
            <h4>Matched Stock Items</h4>
            <b-table
              bordered
              :items="items"
              :fields="[
                { label: 'Select', key: 'select' },
                'item_name',
                { label: 'Fraction', key: 'fraction' },
                'in_stock',
                'rate',
                {
                  key: 'total',
                  label: 'Total',
                  formatter: (value, key, item) =>
                    item.quantity * parseFloat(item.rate),
                },
                {key: 'action', label: ' '}
              ]"
            >
              <template #cell(rate)="i">
                <div style="text-align: right;">
                  {{ i.item.rate.toFixed(2) }}
                </div>
              </template>
              <template #cell(in_stock)="row">
                <span v-if="row.item.stock_balance.length > 0 "> {{ row.item.stock_balance[0] }}</span>
                <span v-else>0</span>
              </template>
              <template #cell(total)="item">
                <div style="text-align: right;">
                  <span>{{ 'KES ' + Number(item.item.quantity * parseFloat(item.item.rate)).toFixed(2).toLocaleString() }}</span>
                </div>
              </template>
              <template #cell(item_name)="it">
                <span
                  v-if="it.item.rate == 0"
                  style="color: red"
                >{{ it.item.item_name }}</span>
                <span v-if="it.item.rate != 0">{{ it.item.item_name }}</span>
              </template>
              <template #cell(select)="row">
                <input
                  v-model="selected"
                  :disabled="row.item.rate == 0 || row.item.in_stock < row.item.fraction"
                  type="radio"
                  variant="primary"
                  name="some-radio"
                  :value="row"
                  @change="onSelect(row.item.item.split(/\s+/).join('-'))"
                >
              </template>
              <template #cell(fraction)="i">
                <b-input
                  v-if="selected.item && selected.item.item === i.item.item"
                  :id="i.item.item.split(/\s+/).join('-')"
                  v-model="i.item.fraction"
                  :disabled="i.item.rate == 0"
                  type="number"
                  name="qty"
                  min="0.01"
                  :value="i.item.fraction"
                />
                <span v-if="!(selected.item && selected.item.item === i.item.item)">{{ i.item.fraction }}</span>
              </template>
              <!-- <template #cell(action)="row">
                <b-button @click="removeMatchedItem(row.item)" variant="danger"
                  >Remove</b-button
                >
              </template> -->
            </b-table>
            <div>
              <span style="color: red;">**(Out of stock items cannot be selected)</span>
              <b-form-checkbox
                id="checkbox-1"
                v-model="dispense"
                name="checkbox-1"
                :value="true"
                :unchecked-value="false"
              >
                Dispense as a one off prescription
              </b-form-checkbox>
              <span style="color: red;">**(The patient will be billed on submit of this)</span>
            </div>
          </div>
        </b-modal>

      </div>
      <h4>Matched Prescriptions</h4>
      <div class="table-responsive">
        <b-table
          :fields="fields"
          class="table"
          stacked="md"
          :per-page="10"
          :current-page="matchedCurrentPage"
          small
          hover
          :items="matchedPrescriptions"
        >
          <template #cell(action)="row">
            <b-button
              style="margin-left: 8px"
              variant="danger"
              @click="undoOrderedprescription({...row.item, name: row.item.name })"
            >
              Undo Matching
            </b-button>
          </template>
        </b-table>
        <b-pagination
          v-model="matchedCurrentPage"
          :total-rows="matchedPrescriptions.length"
          :per-page="10"
          aria-controls="my-table"
        />
      </div>
    </div>
  </div>
</template>
<script>
import {
  getPatientPrescriptionsV2,
  calculatePrescriptionQuantity,
  createMedicationOrder as createMedicationOrderApi,
  getMatchedItems,
  matchStockItemApi,
  getCurrentMedicationOrders,
  getDrugGroups,
  removeMatchedItem as removeMatchedItemApi,
  updateDrugPrescription,
  createDrugSalesOrder,
  cancelInpatientPrescriptions,
  getProcessedMedications,
  undoOrderedprescriptionApi,
  splitPrescriptionsApi,
} from './prescriptions/service'

export default {
  name: 'InpatientPrescriptions',
  props: {
    selectedPharmacy: {
      type: String,
      default: '',
      required: true,
    },
  },
  data() {
    return {
      filters: {},
      dispense: false,
      selected: {},
      tt: false,
      itemGroups: [],
      prescriptionItems: [],
      prescriptionItemsPage: [],
      loading: true,
      alternativeDrugs: [],
      alternativeDrug: null,
      prescriptionItem: null,
      warehouses: [],
      warehouse: null,
      customerInfo: null,
      totalAmount: 0,
      selectedPrescriptions: [],
      selectedTotals: 0,
      prescription: [],
      paidPres: [],
      currentPage: 1,
      matchedPrescriptions: [],
      matchedCurrentPage: 1,
      fields: [
        'name',
        'is_discharge_medication',
        'patient_name',
        'patient',
        'drug_name',
        'is_one_off',
        // { label: 'Strength', key: 'dose' },
        { label: 'Dose', key: 'doctor_dose' },
        'bed',
        'instructions',
        'route_of_administration',
        'start_date',
        'matched_by',
        { label: 'Actions', key: 'action' },
      ],
      imoFields: [
        'drug',
        { key: 'drug_code', label: 'Stock Item' },
        'duration',
        'frequency',
        {
          label: 'Scheduled Time',
          key: 'date',
          formatter: (value, key, item) => moment(`${item.date} ${item.time}`).fromNow(),
        },
        'route_of_administration',
      ],
      imoItems: [],
      activeRow: {},
      items: [],
      addItem: false,
    }
  },
  watch: {
    activeRow() {
      const promises = [calculatePrescriptionQuantity({ period: this.activeRow.duration, dosage: this.activeRow.frequency }), getMatchedItems({
        drug: this.activeRow.drug, strength: this.activeRow.strength, warehouse: this.selectedPharmacy.warehouse, patient: this.activeRow.patient_number, filters: { item: ['!=', ''] },
      })]
      Promise.all(promises).then(([quantity, message]) => {
        this.items = message.map(item => ({
          fraction: 1, quantity: quantity || 1, original_quantity: quantity || 1, in_stock: item.stock_balance, rate: 0, ...item,
        }))
      })
      getCurrentMedicationOrders({ patient: this.activeRow.patient }).then(
        items => {
          this.imoItems = items
        },
      )
    },
  },
  mounted() {
    if (this.filters.service_unit) {
      this.loading = true
      getPatientPrescriptionsV2({ fetch: 'inpatients', ...this.filters }).then(data => {
        this.prescription = data
        this.loading = false
      })
      this.getMatchedPrescriptions()
    }

    getDrugGroups().then(data => {
      this.itemGroups = data
    })
    this.makeFields()
  },
  methods: {
    refresh() {
      if (this.filters.service_unit) {
        this.loading = true
        getPatientPrescriptionsV2({ fetch: 'inpatients', ...this.filters }).then(data => {
          this.prescription = data
          this.loading = false
        })
        this.getMatchedPrescriptions()
      }
    },
    splitPrescription(item) {
      splitPrescriptionsApi({ name: item.name }).then(() => {})
      item.name = `${item.name}1`
      this.prescription = [...this.prescription, item]
    },
    cancel(item) {
      frappe.confirm('Are you sure you want to cancel this prescription?',
        () => {
          cancelInpatientPrescriptions(item.name)
            .then(i => {
              this.prescription = this.prescription.filter(item => item.name !== i)
            })
        }, () => {
        // action to perform if No is selected
        })
    },
    undoOrderedprescription(item) {
      frappe.confirm('Are you sure you want to cancel this prescription?',
        () => {
          undoOrderedprescriptionApi({ prescription_name: item.name })
            .then(i => {
              this.refresh()
            })
        }, () => {
        // action to perform if No is selected
        })
    },
    makeFields() {
      const parent = this
      const customer_field = frappe.ui.form.make_control({
        df: {
          label: 'Service Unit',
          fieldtype: 'Link',
          fieldname: 'service_unit',
          options: 'Healthcare Service Unit',
          placeholder: ('Search Per Service Unit'),
          filters: {
            is_group: 1,
          },
          onchange() {
            parent.filters.service_unit = this.value
            parent.loading = true
            getPatientPrescriptionsV2({ fetch: 'inpatients', ...parent.filters, service_unit: this.value }).then(data => {
              parent.prescription = data
              parent.loading = false
            })
            parent.getMatchedPrescriptions(parent.filters)
          },
        },
        parent: this.$refs['appointment-search'],
        render_input: true,
      })
      const patient_field = frappe.ui.form.make_control({
        df: {
          label: 'Patient',
          fieldtype: 'Link',
          fieldname: 'patient',
          options: 'Patient',
          placeholder: ('Patient'),

          onchange() {
            parent.filters.patient = this.value
            parent.loading = true
            getPatientPrescriptionsV2({ fetch: 'inpatients', ...parent.filters, patient_name: this.value }).then(data => {
              parent.prescription = data
              parent.loading = false
            })
            parent.getMatchedPrescriptions(parent.filters)
          },
        },
        parent: this.$refs['appointment-search'],
        render_input: true,
      })
    },
    onSelect(name) {
      // const name = ev.item.split(/\s+/).join('-')
      const el = document.getElementById(name)
      console.log(name, el)
      if (el) {
        el.focus()
      }
    },
    openEditModal(item) {
      const parent = this
      const previousName = item.drug_name
      const d = new frappe.ui.Dialog({
        title: 'Edit Drug Prescription',
        fields: [{
          label: 'Drug',
          fieldname: 'drug',
          fieldtype: 'Link',
          options: 'Item',
          reqd: true,
          filters: { item_group: ['in', parent.itemGroups], has_variants: 1 },
          default: item.drug,
          onchange: () => {
            this.docGetValue('Item', d.fields_dict.drug.value, 'item_name').then(({ message }) => {
              d.set_value('drug_name', message.item_name)
              d.set_value('instructions', `Take ${message.item_name} ${item.doctor_dose || item.dose || ''} for ${item.duration} ${item.period || ''}`)
              d.refresh()
            })
          },
        }, {
          label: 'Drug Name',
          fieldname: 'drug_name',
          fetch_from: 'drug.item_name',
          fieldtype: 'Data',
          reqd: true,
          default: item.drug_name,
          read_only: 1,
        }, {
          label: 'Instructions',
          fieldname: 'instructions',
          fieldtype: 'Small Text',
          default: item.instructions,
        }],
        primary_action: values => {
          updateDrugPrescription({ ...values, name: item.name })
            .then(res => {
              item.drug_name = res.drug_name
              item.instructions = res.instructions
              item.drug = res.drug
              this.$emit('onUpdateList')
            })
          d.hide()
        },
      })
      d.show()
    },
    openModal(row) {
      this.activeRow = row
      this.$refs.viewPrescription.show()
      console.log(row)
    },
    getMatchedPrescriptions(filters) {
      const payload = filters || this.filters
      // if (payload.service_unit || payload.patient) {
      getProcessedMedications(payload)
        .then(res => this.matchedPrescriptions = res)
      // }
    },
    removeMatchedItem(item) {
      removeMatchedItemApi(item).then(() => {
        const promises = [
          calculatePrescriptionQuantity({
            period: this.activeRow.duration,
            dosage: this.activeRow.frequency,
          }),
          getMatchedItems({
            drug: this.activeRow.drug,
            strength: this.activeRow.strength,
            warehouse: this.selectedPharmacy.warehouse,
            patient: this.activeRow.patient_number,
            filters: { item: ['!=', ''] },
          }),
        ]
        Promise.all(promises).then(([quantity, message]) => {
          this.items = message.map(item => ({
            quantity: quantity || 1,
            original_quantity: quantity || 1,
            fraction: 1,
            in_stock: item.stock_balance,
            rate: 0,
            ...item,
          }))
        })
      })
    },
    createMedicationOrder() {
      if (!this.selected.item) {
        return frappe.throw(
          'Please select an item or add a matched Item before creating a medication order',
        )
      }
      const order = {
        patient: this.activeRow.patient_number,
        patient_encounter: this.activeRow.patient_encounter,
        drug_code: this.selected.item.item,
        dosage: this.activeRow.frequency,
        period: this.activeRow.duration,
        dosage_form: this.activeRow.route_of_administration,
        start_date: this.activeRow.start_date,
        service_unit: this.activeRow.service_unit || '',
        prescription: this.activeRow.name,
        quantity: this.selected.item.quantity,
        fraction: this.selected.item.fraction,
        warehouse: this.selectedPharmacy.warehouse,
        original_quantity: this.selected.item.original_quantity,
      }

      console.log(this.activeRow.is_discharge_medication, '===========>', this.activeRow.frequency * (this.selected.item.quantity / this.selected.item.original_quantity))
      if (this.activeRow.is_discharge_medication || this.dispense) {
        this.createSalesOrders()
        return
      }
      this.docGetValue('Item', this.selected.item.item, 'is_one_off').then(({ message: { is_one_off } }) => {
        if (is_one_off) {
          this.createSalesOrders()
          return
        }
        createMedicationOrderApi(order).then(() => {
          const idx = 0
          this.prescription = this.prescription.filter(item => item.name !== this.activeRow.name)
          this.dispense = false
          const nextItem = this.prescription[0]
          if (nextItem) {
            this.openModal(nextItem)
          }
        // });
        })
      })
    },
    createSalesOrders() {
      const parent = this
      const item = {
        patient: this.activeRow.patient_number,
        encounter: this.activeRow.patient_encounter,
        inpatient_record: this.activeRow.inpatient_record,
        item: this.selected.item.item,
        quantity: this.selected.item.fraction,
        service_unit: this.activeRow.service_unit,
        appointment_name: this.activeRow.patient_appointment,
        doctors_prescription: this.activeRow.name,
        prescription_name: this.activeRow.name,
        route_of_administration: this.activeRow.route_of_administration || '',
        rate: this.selected.item.rate,
      }
      const args = {
        items: [item],
        limit: 0,
        total: parseInt(this.selected.item.rate) * parseInt(this.selected.item.fraction),
        warehouse: this.selectedPharmacy.warehouse,
        is_inpatient: 1,
      }
      console.log(args)
      // return
      createDrugSalesOrder(args).then(() => {
        const idx = 0
        this.prescription = this.prescription.filter(item => item.name !== this.activeRow.name)
        const nextItem = this.prescription[0]
        this.dispense = false
        if (nextItem) {
          this.openModal(nextItem)
        }
        this.$emit('onUpdateList')
      })
    },
    addAMatch(item) {
      const d = new frappe.ui.Dialog({
        title: 'Add Matching Stock Item',
        fields: [
          {
            label: 'Item',
            fieldname: 'item',
            fieldtype: 'Link',
            options: 'Item',
            filters: { item_group: ['in', this.itemGroups], has_variants: 0 },
          },
          {
            label: 'Strength',
            fieldname: 'strength',
            fieldtype: 'Data',
          },
        ],
        primary_action: values => {
          matchStockItemApi({
            ...values,
            drug: item.drug,
            dosage_form: item.dosage_form,
          }).then(() => {
            this.msgprint(
              `${item.drug} matched successfully with ${values.item}`,
              5,
            )
            const promises = [calculatePrescriptionQuantity({ period: this.activeRow.duration, dosage: this.activeRow.frequency }), getMatchedItems({
              drug: this.activeRow.drug, strength: this.activeRow.strength, patient: this.activeRow.patient_number, warehouse: this.selectedPharmacy.warehouse, filters: { item: ['!=', ''] },
            })]
            Promise.all(promises).then(([quantity, message]) => {
              this.items = message.map(item => ({
                fraction: 1, quantity: quantity || 1, original_quantity: quantity || 1, in_stock: item.stock_balance, rate: 0, ...item,
              }))
            })
            d.hide()
          })
        },
      })
      d.show()
    },
  },
}
</script>
<style scoped>
.root {
  height: 85vh;
  overflow: scroll;
}
.search {
  display: flex;
}
</style>
