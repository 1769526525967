import useJwt from '@/auth/jwt/useJwt'

export const api = ({ method, args = {}, freeze = true }) => new Promise((resolve, reject) => useJwt.api(method, args)
  .then(response => resolve(response.data))
  .catch(error => reject(error)))

export const getCurrentDateMedicationOrders = ({
  patient,
}) => api({
  method: 'clinical.api.inpatient_drug_administration.inpatient_drug_administration.get_medication_orders_v2',
  args: {
    patient,
  },
}).then(({ message }) => message)

export const markOccupancyMedicationsBilled = ({
  occupancy_name,
}) => api({
  method: 'clinical.api.inpatient_drug_administration.inpatient_drug_administration.set_inpatient_record_medication_billed',
  args: {
    occupancy_name,
  },
}).then(({ message }) => message)

export const getPatientsPerServiceUnit = ({
  serviceUnit: service_unit,
  servicePoint: service_point,
  search = '',
  traverse = '',
  passedDate: passed_date = '',
}) => api({
  method: 'clinical.api.patients.get_patients_per_service_unit',
  args: {
    service_unit,
    service_point,
    search,
    traverse,
    passed_date,
  },
  freeze: false,
}).then(({ message }) => message)
