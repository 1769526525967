import {
  prescriptionItemMaterialRequest,
}
  from '../../pharmacy/working-area/components/prescriptions/service'

export default {
  createMaterialRequest({ commit }, payload) {
    prescriptionItemMaterialRequest({
      drug: payload.drug,
      quantity: payload.quantity,
      warehouse: payload.warehouse,
      from_warehouse: payload.from_warehouse,
    }).then(result => {
      if (result) {
        frappe.show_alert({
          message: 'Material Request Created. Awaiting response',
          indicator: 'green',
        }, 5)
      } else {
        frappe.show_alert({
          message: 'Request Failed. Please try again',
          indicator: 'red',
        }, 5)
      }
    })
  },
}
