<template>
  <ul
    v-if="pager.pages && pager.pages.length"
    class="pagination"
    :style="ulStyles"
  >
    <li
      class="page-item first"
      :class="{ disabled: pager.currentPage === 1 }"
      :style="liStyles"
    >
      <a
        class="page-link"
        :style="aStyles"
        @click="setPage(1)"
      >{{
        labels.first
      }}</a>
    </li>
    <li
      class="page-item previous"
      :class="{ disabled: pager.currentPage === 1 }"
      :style="liStyles"
    >
      <a
        class="page-link"
        :style="aStyles"
        @click="setPage(pager.currentPage - 1)"
      >{{ labels.previous }}</a>
    </li>
    <li
      v-for="page in pager.pages"
      :key="page"
      class="page-item page-number"
      :class="{ active: pager.currentPage === page }"
      :style="liStyles"
    >
      <a
        class="page-link"
        :style="aStyles"
        @click="setPage(page)"
      >{{
        page
      }}</a>
    </li>
    <li
      class="page-item next"
      :class="{ disabled: pager.currentPage === pager.totalPages }"
      :style="liStyles"
    >
      <a
        class="page-link"
        :style="aStyles"
        @click="setPage(pager.currentPage + 1)"
      >{{ labels.next }}</a>
    </li>
    <li
      class="page-item last"
      :class="{ disabled: pager.currentPage === pager.totalPages }"
      :style="liStyles"
    >
      <a
        class="page-link"
        :style="aStyles"
        @click="setPage(pager.totalPages)"
      >{{ labels.last }}</a>
    </li>
  </ul>
</template>

<script>
// https://github.com/cornflourblue/jw-vue-pagination/blob/master/src/JwPagination.vue
// https://jasonwatmore.com/post/2019/08/21/vue-js-simple-pagination-example
import paginate from './paginate'

const defaultLabels = {
  first: 'First',
  last: 'Last',
  previous: 'Previous',
  next: 'Next',
}
const defaultStyles = {
  ul: {
    margin: 0,
    padding: 0,
    display: 'inline-block',
  },
  li: {
    listStyle: 'none',
    display: 'inline',
    textAlign: 'center',
  },
  a: {
    cursor: 'pointer',
    padding: '6px 12px',
    display: 'block',
    float: 'left',
  },
}
export default {
  name: 'Paginator',
  props: {
    items: {
      type: Array,
      required: true,
    },
    initialPage: {
      type: Number,
      default: 1,
    },
    pageSize: {
      type: Number,
      default: 10,
    },
    maxPages: {
      type: Number,
      default: 10,
    },
    labels: {
      type: Object,
      default: () => defaultLabels,
    },
    styles: {
      type: Object,
    },
    disableDefaultStyles: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      pager: {},
      ulStyles: {},
      liStyles: {},
      aStyles: {},
    }
  },
  watch: {
    items() {
      this.setPage(this.initialPage)
    },
  },
  created() {
    if (!this.$listeners.changePage) {
      throw 'Missing required event listener: "changePage"'
    }
    // set default styles unless disabled
    if (!this.disableDefaultStyles) {
      this.ulStyles = defaultStyles.ul
      this.liStyles = defaultStyles.li
      this.aStyles = defaultStyles.a
    }
    // merge custom styles with default styles
    if (this.styles) {
      this.ulStyles = { ...this.ulStyles, ...this.styles.ul }
      this.liStyles = { ...this.liStyles, ...this.styles.li }
      this.aStyles = { ...this.aStyles, ...this.styles.a }
    }
    // set to initial page
    this.setPage(this.initialPage)
  },
  methods: {
    setPage(page) {
      const { items, pageSize, maxPages } = this
      // get new pager object for specified page
      const pager = paginate(items.length, page, pageSize, maxPages)
      // get new page of items from items array
      const pageOfItems = items.slice(pager.startIndex, pager.endIndex + 1)
      // update pager
      this.pager = pager
      // emit change page event to parent component
      this.$emit('changePage', pageOfItems)
    },
  },
}
</script>
