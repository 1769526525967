<template>
  <b-container
    class="side-bar-area aside-bottom-padding"
    style="border-radius: 10px;height: 75vh;"
  >
    <b-row
      class="w-100"
      align-h="between"
    >
      <div class="awesomplete w-70">
        <b-form-input
          id="navbar-search"
          v-model="searchTerm"
          class="form-control rounded w-100"
          placeholder="Search patients"
          size="sm"
          aria-haspopup="true"
          @focus="placeholder = 'Search patient..'"
          @blur="placeholder = 'Search patient...'"
        />
      </div>

      <b-button
        size="sm"
        variant="secondary"
        @click="searchClicked"
      >
        Go
      </b-button>
    </b-row>
    <div class="content-area">
      <b-row>
        <b-col cols="12">
          <div
            v-if="loading"
            style="padding-top:60%; text-align:center; height:100vh;"
          >
            <b-spinner style="width: 100px; height: 100px;margin:auto;" />
          </div>
          <div
            v-else
            style=""
          >
            <div style="z-index:initial;">
              <b-list-group
                style="width:100%;"
                class="top-margin-space"
              >
                <b-list-group-item
                  v-for="(queueListItem, idx) in queue"
                  :key="queueListItem.name"
                  :ref="queueListItem.patient"
                  :class="{ 'active': idx == activeIndex }"

                  @click="queueListItemClicked(queueListItem,idx)"
                >
                  <b-row
                    class="w-100"
                    aligh-v="center"
                  >
                    <b-col
                      cols="3"
                      style="padding-left: 0px !important;"
                    >
                      <b-avatar
                        style="color:black;border:2px solid #0EBFE9"
                        :text="(idx + 1)"
                        :size="avatarSize"
                      />

                    </b-col>
                    <b-col
                      cols="9"
                      style="padding-left: 0px  !important; padding-right: 0px !important;"
                    >
                      <b-row style="font-size: 12px !important;">
                        <p
                          class="w-100"
                          style="margin-bottom: 2px !important;"
                        > {{ queueListItem.patient_name }} <b-badge
                          style="border:1px solid gray"
                          pill
                          variant="primary"
                        >{{
                          queueListItem.age + "(" + (queueListItem.gender
                            || '').charAt(0) + ")" }}</b-badge></p>
                        <b-badge
                          style="text-transform: capitalize;"
                          :variant="getVariant(queueListItem.status)"
                          pill
                        >
                          <span style=""> {{ queueListItem.status }}</span>
                        </b-badge>

                      </b-row>

                    </b-col>
                  </b-row>
                </b-list-group-item>
                <div style="min-height: 100px; width: 100%;" />
              </b-list-group>
            </div>
          </div>

        </b-col>
      </b-row>
    </div>

  </b-container>
</template>

<script>
import SidebarItem from './SidebarItem.vue'
import WarehouseSelect from './WarehouseSelect.vue'
import SearchInput from '../../clinical/sidebar/SearchInput.vue'

import { getPrescriptionQueue, searchPrescriptionQueue } from './services'

export default {
  components: {
    SidebarItem,
    WarehouseSelect,
    SearchInput,
  },
  filters: {
    // Filter definitions
    Upper(value) {
      return value.toUpperCase()
    },
    title(str) {
      return str.replace(/\w([^-\s]*)/g, (txt = '') => (txt || '').charAt(0).toUpperCase() + txt.substr(1).toLowerCase())
    },
    last_5(str) {
      return str.substring(str.length - 5)
    },
    nameInitials(fullName) {
      return fullName
        .split(' ')
        .map((name, index) => (name && index < 1 ? name[0] : ''))
        .join('')
    },
  },
  props: {
    currentServicePoint: {
      type: Object,
      required: true,
      default: {},
    },
  },
  data() {
    return {
      queueList: [],
      queue: [],
      servicePoint: null,
      prescriptions: [],
      loading: false,
      patientSearchQuery: '',
      warehouse: null,
      activeIndex: 0,
      socket: undefined,
      searchTerm: '',
    }
  },
  watch: {
    queueList() {
      console.log(this.queueList)
      const inList = []
      const newList = []
      this.queueList.forEach(e => {
        if (inList.indexOf(e.patient) === -1) {
          newList.push(e)
          inList.push(e.patient)
        }
      })
      this.queue = newList
      console.log('>>>>>>>', newList)
    },
    servicePoint() {
      if (this.servicePoint.name) {
        this.getQueueList()
        // this.searchClicked()
      }
    },
  },
  mounted() {
    const parent = this
    if (!this.servicePoint) {
      this.servicePoint = this.currentServicePoint
    }
    if (this.servicePoint.name) {
      this.getQueueList()
    }
  },
  methods: {
    searchClicked() {
      const search = this.searchTerm || ' '
      this.searchTerm = ''
      if (search && search.length) {
        searchPrescriptionQueue({
          service_point: this.servicePoint && this.servicePoint.name,
          query: search,
        }).then(r => {
          this.queueList = r
          r.forEach((item, index) => {
            // this.socket.on(item.name, function(data) {});
          })
        })
      } else if (this.servicePoint) {
        this.onSelectedServicePoint(this.servicePoint)
      }
    },
    getVariant(status) {
      const variant = {
        checkin: 'danger',
        invoiced: 'primary',
        'payment complete': 'warning',
      }
      return variant[(status || '').toLowerCase()] || 'info'
    },
    getQueueList() {
      const parent = this
      getPrescriptionQueue({ service_point: this.servicePoint.name }).then(result => {
        console.log(result)
        if (result && result.length > 0) {
          parent.queueListItemClicked(result[0])
          parent.queueList = result
          result.forEach((item, index) => {
            // parent.socket.on(item.name, function(data) {
            //     parent.upsertQueueList(data);
            // });
          })
        } else {
          this.msgprint({
            message: `No queue for ${servicePoint.point_name}`,
            indicator: 'green',
          }, 10)
        }
      })
    },
    upsertQueueList(listItem) {
      let replaced = false
      const parent = this
      parent.queue.map((item, x) => {
        if (item.name === listItem.name) {
          if (listItem.action && listItem.action === 'Transfer') {
            // this.onSelectedServicePoint(this.servicePoint);
            this.$delete(this.queueList, x)
            replaced = true
          } else if (listItem.action && listItem.action === 'Removed') {
            // this.onSelectedServicePoint(this.servicePoint);
            this.$delete(this.queueList, x)
            replaced = true
          } else {
            Vue.set(this.queueList, x, listItem)
            this.$emit('queueStateUpdated', listItem)
            replaced = true
          }
        }
      })
      if (!replaced) {
        this.queueList.push(listItem)
        // parent.socket.on(listItem.name, function(data) {
        //     parent.upsertQueueList(data);
        // });
        if (this.queueList && this.queueList.length === 1) {
          this.queueListItemClicked(listItem)
        }
      }
    },
    queueListItemClicked(queueListItem, idx) {
      this.toggleActive(idx)
      this.$emit('prescriptionClicked', queueListItem)
    },
    toggleActive(index) {
      this.activeIndex = index
    },
    onSelectedServicePoint(servicePoint) {
      this.servicePoint = servicePoint
      // this.createSearchControl(servicePoint);
      const parent = this
      // this.socket.on(servicePoint.name, function(data) {
      //     parent.upsertQueueList(data);
      //     this.msgprint({
      //         message: `Queue Update on ${data.patient_name.replace(/^\w/, c => c.toUpperCase())}`,
      //         indicator: 'green'
      //     }, 50);
      // });
      this.queueList = []
      this.$emit('prescriptionClicked', {})
      if (servicePoint.name) {
        getPrescriptionQueue({ service_point: servicePoint.name }).then(result => {
          if (result && result.length > 0) {
            parent.queueListItemClicked(result[0])
            parent.queueList = result
            result.forEach((item, index) => {
              // parent.socket.on(item.name, function(data) {
              //     parent.upsertQueueList(data);
              // });
            })
          } else {
            this.msgprint({
              message: `No queue for ${servicePoint.point_name}`,
              indicator: 'green',
            }, 10)
          }
        })
      }
    },
    prescriptionClicked(prescription) {
      this.$emit('prescriptionClicked', prescription)
    },
    createSearchControl(servicePoint) {

    },
    get_host(port = 3000) {
      let host = window.location.origin
      if (window.dev_server || true) {
        const parts = host.split(':')
        port = frappe.boot.socketio_port || port.toString() || '3000'
        if (parts.length > 2) {
          host = `${parts[0]}:${parts[1]}`
        }
        host = `${host}:${port}`
      }
      return host
    },
  },

}
</script>

<style scoped>
.search-bar {
    flex: 1;
    max-width: 3000px;
}

.list-group-item {
    position: relative;
    display: block;
    padding: 8px 1.25rem;
    background-color: #fff;
}

.list-group-item:hover {
    background: lightgray;
    cursor: pointer;
}

.list-group-item-secondary {
    /* -webkit-box-shadow: -3px 0px 3px 4px rgba(27, 21, 76, 0.7);
  box-shadow: -3px 0px 3px 4px rgba(27, 21, 76, 0.7); */
    border-left: 5px solid #7c2112;
}

.list-group-item-secondary {
    background: #1f19192b;
}

.b-avatar {
    font-size: inherit;
    font-weight: 400;
    line-height: 1;
    text-align: center;
    background: white;
    color: black;
    border: 1px solid darkgray;
}

.fixed-header {
    width: 100%;
    position: fixed;
    top: 400px;
}

.fixed-header {
    top: 0;
}

.active {
    color: black;
    /* border-left: 5px solid #2490ef;
    border-right: 5px solid #2490ef; */
    background-color: lightgray;
    border-left: 5px solid #06a79e;
    border-top: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
}

.tb-row {
    cursor: pointer;
}

body {
    font-family: sans-serif;
    font-size: 16px;
    color: #fff;
}

ul.sidebar-menu {
    margin: 0;
    padding: 0;
    max-width: 450px;
    list-style: none;
    list-style-type: none;
}

.sidebar-menu li a span {
    margin-right: 20px;
    color: #fff;
}

.sidebar-menu li a {
    background-color: #d7dde2;
    padding: 10px 15px;
    display: block;
    text-decoration: none;
    color: #fff;
}

.sidebar-menu li a:hover {
    background-color: #d7dde2;
    padding: 20px 25px;
    display: block;
    text-decoration: none;
    color: #fff;
}

li.have-children ul {
    padding: 0px;
}

li.have-children ul li a {
    background-color: #d7dde2;
    padding-left: 62px;
}

li.have-children ul li a:hover {
    color: #fff;
    background-color: #d7dde2;
    padding-left: 62px;
}

li.have-children,
li {
    position: relative;
}

.have-children span::after {
    position: absolute;
    right: 30px;
    content: "\f054";
    color: #fff;
    transition: all .5s;
}

li.active.have-children span::after {
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
}

.sidebar-menu .have-children>ul {
    display: none;
}

.no-sides {
    margin-left: 0px;
    margin-right: 8px;
}

.side-bar-area {
    background: rgba(211, 211, 211, 0.423);
    width: 100% !important;
    padding-top: 20px;
    min-height: 400px;
    height: 80vh;
    overflow-y: hidden;
    overflow-x: hidden;

    padding-left: 11px !important;
    padding-right: 11px !important;
}

.name-header {
    background: #b1d0eb;
    color: white;
    padding: 14px;
    border-radius: 0px 16px 16px 0px;
    width: 95%;
    font-weight: 800;
}

.search-input {
    margin-top: 5%;
}

.no-results {
    margin-top: 5%;
    padding-left: 7%;
}

.rounded {
    border-radius: 12px;
}

.top-margin-space {
    padding-bottom: 8px;
    height: 85vh;
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-width: thin;
    background: white;
    position: absolute;
}

.no-border {
    border: 0px solid black !important;
}

.form-control {
    padding: 0.438rem 1rem;
    background-color: #fff;
    background-clip: padding-box;
    border: 0px solid #d8d6de;
    border-radius: 0.357rem;
    width: 100%;
    font-weight: 400;
    line-height: 1.45;
    font-size: 13px;
    color: #6e6b7b;
    -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

.btn-custom {
    height: 36px;
}

.menu-options {
    margin-left: 0px;
    font-size: 18px;
    margin-top: 12px;
    width: 100%;
}

.closed {
    padding-right: 0px !important;
    padding-left: 6px !important;
}

.psm {
    display: inline-block;
    border-radius: 50px;
    box-shadow: 0px 0px 2px #888;
    height: 40px;
    width: 40px;
    text-align: center;
    position: relative;
    padding-bottom: 10px;
    position: relative;
    margin-top: 5px;

}

#ico {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 60%;
    transform: translate(-50%, -50%);
    width: 20px;
    height: 20px;
    display: block;
    color: black;
    font-weight: 700px;
}

.content-area {
    height: 74vh;
    overflow-y: hidden;
    overflow-x: hidden;
    scrollbar-width: thin;
    background: white;
    padding-bottom: 16px;
    padding-left: -5px;
    border-radius: 5px;
    margin-top: 10px;
    position: relative;
}
</style>
