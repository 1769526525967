import useJwt from '@/auth/jwt/useJwt'

export const api = ({ method, args = {}, freeze = true }) => new Promise((resolve, reject) => useJwt.api(method, args)
  .then(response => resolve(response.data.message))
  .catch(error => reject(error)))

export const getPrescriptionQueue = ({
  start = 0,
  page_length = 20,
  service_point,
}) => api({
  method: 'clinical.api.queue.pharmacy.get_queue_state',
  args: {
    start,
    page_length,
    service_point,
  },
})

export const searchPrescriptionQueue = ({
  service_point = '',
  query,
}) => api({
  method: 'clinical.api.queue.pharmacy.search_queue_state',
  args: {
    service_point,
    query,
  },
})

export const getWarehouseServicePoints = ({
  warehouse_name,
}) => api({
  method: 'clinical.api.warehouse.user_warehouse.get_service_point_list',
  args: {
    warehouse_name,
  },
})

export const getUserWarehouses = ({
  user_name,
}) => api({
  method: 'clinical.api.warehouse.user_warehouse.get_user_warehouses',
  args: {
    user_name,
  },
})
