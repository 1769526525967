import useJwt from '@/auth/jwt/useJwt'

export const api = ({ method, args = {}, freeze = true }) => new Promise((resolve, reject) => useJwt.api(method, args)
  .then(response => resolve(response.data.message))
  .catch(error => reject(error)))

export const getUserWarehouses = ({
  user_name,
}) => api({
  method: 'clinical.api.warehouse.user_warehouse.get_user_warehouses',
  args: {
    user_name,
  },
})

export const getWarehouseServicePoints = ({
  warehouse_name,
}) => api({
  method: 'clinical.api.warehouse.user_warehouse.get_service_point_list',
  args: {
    warehouse_name,
  },
})
