<template>
  <div class="ward-selection">
    <b-row class="no-background">
      <!-- {{ warehouses }} {{ servicePoints }} -->
      <b-col
        v-if="selectedWarehouse && selectedWarehouse.warehouse"
        cols="10"
        class="no-background"
      >
        <b-dropdown
          :text="selectedWarehouse.warehouse"
          variant="primary"
          split
          split-variant="outline-secondary"
          class="fill-width custom-menu-bg"
          style="width:100%;margin:auto;"
        >
          <b-dropdown-item
            v-for="warehouse in warehouses"
            :key="warehouse.name"
            style="text-transform: capitalize;"
            @click="setWarehouse(warehouse)"
          >
            {{ warehouse.warehouse }}
          </b-dropdown-item>
        </b-dropdown>
      </b-col>
      <b-col md="2">
        <!-- <button @click="refresh" class="text-muted btn btn-default  icon-btn" title="" style="margin:auto;" data-original-title="Refresh">
                            <svg class="icon icon-md" style="">
                            <use class="" href="#icon-refresh"></use>
                            </svg>
                        </button> -->
      </b-col>
    </b-row>
    <!-- <div style="margin-top:16px;">
            <b-dropdown v-if="selectedServicePoint && selectedServicePoint.point_name" :text="selectedServicePoint.point_name + ' ' + servicePoint.service_type" variant="primary" split split-variant="outline-secondary" class="fill-width custom-menu-bg" style="width:100%;margin:auto;">
                <b-dropdown-item v-for="servicePoint in servicePoints" :key="servicePoint.name" @click="setServicePoint(servicePoint)">{{ servicePoint.point_name }}: {{ servicePoint.service_type }} </b-dropdown-item>
            </b-dropdown>
        </div> -->
  </div>
</template>

<script>

import warehouseModule from '@/views/erp/state/pharmacy-warehouse'
import {
  getUserWarehouses,
  getWarehouseServicePoints,
} from './services'

export default {
  name: 'WarehouseSelect',
  props: {
    servicePoint: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      // warehouses: [],
      // selectedWarehouse: null,
      // servicePoints: null,
      // selectedServicePoint: null
    }
  },
  computed: {
    // warehouses() {
    //     return this.$store.getters["warehouseModule/warehouses"];
    // },
    // servicePoints() {
    //     return this.$store.getters["warehouseModule/servicePoints"];
    // },
    //  selectedWarehouse() {
    //     return this.$store.getters["warehouseModule/selectedWarehouse"];
    // },
    //  selectedServicePoint() {
    //     return this.$store.getters["warehouseModule/selectedServicePoint"];
    // },
  },
  watch: {
    selectedWarehouse() {
      // this.$store.dispatch("warehouseModule/fetchWarehouseServicePoints", this.selectedWarehouse.warehouse);

      getWarehouseServicePoints({ warehouse_name: this.selectedWarehouse.warehouse }).then(result => {
        if (result && result.length > 0) {
          this.servicePoints = result
          this.setServicePoint(result[0])
        } else {
          this.msgprint({
            message: 'No service Point(s) for this warehoue.',
            indicator: 'red',
          }, 5)
        }
      })
    },
    warehouses() {
      // alert('warehouses')
      this.setWarehouse(this.warehouses[0])
    },
    servicePoints() {
      // alert('servicePoints')
      this.setServicePoint(this.servicePoints[0])
    },
  },
  created() {
    this.$store.registerModule('warehouseModule', warehouseModule)
  },
  mounted() {
    this.getWarehouses()
  },
  methods: {
    refresh() {
      if (this.selectedWarehouse && this.selectedServicePoint) {
        this.$emit('warehouseSelected', this.selectedWarehouse)
        this.$emit('selectedServicePoint', this.selectedServicePoint)
      }
    },
    setWarehouse(warehouse) {
      this.selectedWarehouse = warehouse
      this.$emit('warehouseSelected', warehouse)
    },
    setServicePoint(servicePoint) {
      this.selectedServicePoint = servicePoint
      this.$emit('selectedServicePoint', servicePoint)
    },
    getWarehouses() {
      // this.$store.dispatch("warehouseModule/fetchUserWarehouses", '');

      getUserWarehouses({
        user_name: this.getUserSession().user,
      }).then(result => {
        if (result && result.length > 0) {
          this.warehouses = result
          this.setWarehouse(result[0])
          this.loading = false
        } else {
          this.msgprint({
            message: 'You are not assigned to any warehouse.\nContact Support for help.',
            indicator: 'red',
          }, 5)
        }
      })
    },
  },
}
</script>

<style scoped>
.fill-width {
    width: 100% !important;
}

.firt-text {
    font-size: large;
}

.cust-nav {
    box-shadow: 0px 0px !important;
    padding: 0px !important;
}

.ward-selection {
    background: rgba(76, 175, 80, 0);
    padding: auto;
}

.unit-title {
    font-weight: 700;
    color: dimgray;
    margin-bottom: 0px !important;
}

.no-background {
    background: rgba(76, 175, 80, 0);
}

.custom-menu-bg {
    background: white;
    border-radius: 7px;
    border: 0px;
}
</style>
