<template>
  <div>
    <!-- {{ refills }} -->
    <b-row>
      <b-col>
        <b-alert
          variant="primary"
          show
        >
          Rates may be different due to new stock, click refresh rate in actions to update
        </b-alert>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <table class="table table-striped table-bordered s">
          <thead>
            <tr>
              <!-- <th>Name</th> -->
              <th>Date</th>
              <th>Drug</th>
              <th>Refill Qty</th>
              <th>Quantity</th>
              <th>S.Order</th>

              <th>Rate</th>
              <th>Amount</th>
              <!-- <th>Account</th> -->
              <th>Action</th>
            </tr>
          </thead>
          <tr
            v-for="refillrefills in refills"
            :key="refillrefills.name"
          >
            <!-- <td>{{ refillrefills.name }}</td> -->
            <td><span>{{ refillrefills.creation | dateFormat }}</span> </td>
            <td>{{ refillrefills.drug_code }}</td>
            <td>{{ refillrefills.refill_quantity | numberWithCommas }}</td>
            <!-- <td><span>{{ refillrefills.dispensed_quantity | numberWithCommas }}</span> </td>
                                            @change="itemUnitsChanged"
                                            @input="itemUnitsChanged"

                             -->
            <td>
              <span
                v-if="!refillrefills.sales_order"
                style="max-width: 70px;"
              >
                <input
                  id="numberOfUnits"
                  :ref="refillrefills.drug_prescription"
                  v-model.trim="refillrefills.dispensed_quantity"
                  type="number"
                  min="1"
                  style="width: 60px;"
                  @change="numberOfUnitsChange(refillrefills)"
                >
              </span>
              <span
                v-else
                class="form-group"
              >
                {{ refillrefills.dispensed_quantity }}
              </span>
            </td>
            <td>{{ refillrefills.sales_order || 'Not Invoiced' }}</td>
            <td>{{ refillrefills.rate.toFixed(2) | numberWithCommas }}</td>
            <td style="text-align: right;"><span>
              {{
                Math.ceil(refillrefills.rate * refillrefills.dispensed_quantity).toFixed(2) ||
                  1 | numberWithCommas
              }}
            </span> </td>
            <td>
              <b-dropdown
                id="dropdown-1"
                variant="outline-primary"
                text="Actions"
                class="m-md-2"
              >
                <b-dropdown-item
                  v-if="refillrefills.sales_order"
                  variant="primary"
                  @click="dispense(refillrefills)"
                >
                  Dispense
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="!refillrefills.sales_order"
                  variant="primary"
                  @click="createSalesOrder(refillrefills)"
                >
                  Sales Order
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="!refillrefills.sales_order"
                  variant="primary"
                  @click="refreshRefillRate(refillrefills)"
                >
                  Refresh Rates
                </b-dropdown-item>
              </b-dropdown>
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  getPrescriptionRefills,
  updatePrescriptionRefillRate,
  updatePrescriptionRefillQuantity,
  createRefillSalesOrder,
  dispensePrescriptionRefill,
} from './prescriptions/service'

export default {
  name: 'Refills',
  filters: {
    // Filter definitions
    dateFormat(date) {
      const current_datetime = new Date(date)
      return `${current_datetime.getFullYear()}-${current_datetime.getMonth() + 1}-${current_datetime.getDate()} ${
        current_datetime.getHours()}:${current_datetime.getMinutes()}:${current_datetime.getSeconds()}`
    },
    Upper(value) {
      return value.toUpperCase()
    },
    numberWithCommas(x) {
      if (x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return 0
    },
  },
  props: {
    patient_number: {
      type: String,
      default: ' ',
      required: true,
    },
  },
  data() {
    return {
      refills: [],
    }
  },
  watch: {
    patient_number() {
      this.getRefills()
    },
  },
  created() {
    this.getRefills()
  },
  methods: {
    dispense(refillrefills) {
      dispensePrescriptionRefill({ refill_name: refillrefills.name }).then(r => {
        this.msgprint({
          message: 'Refill Dispensed',
          indicator: 'green',
        }, 5)
        this.getRefills()
      })
    },
    numberOfUnitsChange(refillrefills) {
      updatePrescriptionRefillQuantity({
        refill_name: refillrefills.name,
        qty: refillrefills.dispensed_quantity,
      }).then(result => {
        this.loading = false
        this.refills.map((item, x) => {
          if (item.name === result.name) {
            Vue.set(this.refills, x, result)
          }
        })
        this.msgprint({
          message: `Drug  #${refillrefills.drug_code} unit changed.`,
          indicator: 'green',
        }, 5)
        // this.itemUnitsChanged();
      })
    },
    createSalesOrder(refillrefills) {
      createRefillSalesOrder({ refill_doc_name: refillrefills.name }).then(r => {
        this.msgprint({
          message: 'Sales Order created',
          indicator: 'green',
        }, 5)
        this.getRefills()
      })
    },
    updateRefillQty(refillItem, qty) {
      updatePrescriptionRefillQuantity({
        refill_name: refillItem.name,
        qty,
      }).then(r => {
        this.msgprint({
          message: `Quantity for ${r.name} updated`,
          indicator: 'green',
        }, 5)
        this.getRefills()
      })
    },
    refreshRefillRate(refillrefills) {
      // refill_name
      updatePrescriptionRefillRate({ refill_name: refillrefills.name }).then(response => {
        this.msgprint({
          message: `Price rate for ${response.name} updated`,
          indicator: 'green',
        }, 5)
        this.getRefills()
      })
    },
    getRefills() {
      getPrescriptionRefills({ patient_name: this.patient_number })
        .then(data => {
          //    console.log(JSON.stringify(data));
          this.refills = data
        })
    },
  },
}
</script>
