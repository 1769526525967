<template>
  <div class="" style="background-color:#ffffff; border-radius: 10px;height: 75vh;">
    <b-row class="w-100">
      <TopHeader style="width: 100%" @warehouseSelected="warehouseSelected"
        @selectedServicePoint="selectedServicePoint" />
    </b-row>
    <b-tabs variant="secondary"  v-model="tab" fill content-class="note-tabs-content" class="custom-tabs" >

      <b-tab title="OUTPATIENT">
        <!-- <template #title>
          <i class="fa-solid fa-hospital" aria-hidden="true" style="color: green; margin-right: 2px;" /> <span
            style="color: green;">OUTPATIENT PRESCRIPTIONS</span>
        </template> -->
        <b-row>
          <b-col class="d-none d-md-block"  cols="3" style="padding: 2px !important;">
            <div class="sidebar ">
              <Sidebar v-if="tab === 0" ref="sidebar" 
                :current-service-point="currentServicePoint" @prescriptionClicked="prescriptionClicked"
                @queueStateUpdated="sidebarQueueStateUpdated" />
            </div>
          </b-col>
          <b-col cols-md="9" cols-sm="12" style="padding: 0px !important;">
            <div>
              <Container v-if="tab === 0" ref="prescriptionContainer" :selected-pharmacy="warehouse"
                :patient-name="prescription.patient" :encounter-name="prescription.encounter"
                :queue-state="prescription" />
            </div>
          </b-col>
        </b-row>
      </b-tab>
      <b-tab title="INPATIENT">
        <!-- <template #title>
          <i class="fa fa-bed" aria-hidden="true" style="color: royalblue; margin-right: 2px;" /> <span
            style="color: royalblue;">INPATIENT PRESCRIPTIONS</span>
        </template> -->
        <b-row class="w-100">
          <InpatientMedications :selected-pharmacy="warehouse" />
        </b-row>
      </b-tab>

     

    </b-tabs>
  </div>
</template>

<script>
import TopHeader from '@/views/erp/pharmacy/topheader/Topheader.vue'
import Receipts from '@/views/erp/billing/components/receipts/Receipts.vue'
import Sidebar from './side-bar/Sidebar.vue'
import Container from './working-area/WorkingArea.vue'
import InpatientPrescriptions from './working-area/components/InpatientPrescriptions.vue'
import OutgoingRequest from './working-area/components/material-request/OutgoingRequest.vue'
import InpatientMedications from './working-area/components/inpatients/InpatientMedications.vue'
import WarehouseStockBalance from './working-area/components/WarehouseStockBalance.vue'

export default {
  name: 'Main',
  components: {
    Sidebar,
    Container,
    TopHeader,
    OutgoingRequest,
    InpatientPrescriptions,
    Receipts,
    WarehouseStockBalance,
    InpatientMedications,
  },
  data() {
    return {
      prescription: {},
      socket: null,
      warehouse: null,
      tab: 0,
      currentServicePoint: {},
    }
  },
  computed: {
    socketData() {
      return this.$store.getters['socket/getSocketData']
    },
  },
  watch: {
    prescription() {
      this.$refs.prescriptionContainer.itemUnitsChanged()
    },
    tab() {
      this.selectedServicePoint(this.currentServicePoint)
    },
  },
  watch: {
    socketData(socketData) {
      // console.log("SOCKET DATA", socketData);
      if (socketData.action === 'off') {
        this.socket.off(socketData.event)
      } else if (socketData.action === 'on') {
        this.socket.on(socketData.event, () => {
          console.log(`subscribed to ${socketData.event}`)
        })
      }
    },
  },
  mounted() {
    // $('body').css({ 'overflow-y': 'hidden' });
    // $('body').css({ 'overflow-x': 'hidden' });
    this.setTitle("Pharmacy")
    this.$store.commit('verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED', true)

    this.socket = window.socket
    socket.on('connect', () => {
      console.log('Pharmacy main connected')
    })
  },
  created() {
    // this.$store.registerModule("socket", socketModule);
  },
  methods: {
    selectedServicePoint(sp) {
      this.currentServicePoint = sp
      this.$refs.sidebar.onSelectedServicePoint(sp)
    },
    warehouseSelected(wh) {
      this.warehouse = wh
    },
    sidebarQueueStateUpdated(queueState) {
      this.prescription = queueState
      this.$refs.prescriptionContainer.getPrescriptionDrugs(queueState.patient, queueState.appointment)
    },
    prescriptionClicked(prescription) {
      this.prescription = prescription
      if (prescription.patient && prescription.appointment) {
        this.$refs.prescriptionContainer.getPrescriptionDrugs(prescription.patient, prescription.appointment)
      }
    },
    onSocket(data) {
      console.log(data)
    },
    get_host(port = 3000) {
      let host = window.location.origin
      if (window.dev_server || true) {
        const parts = host.split(':')
        port = frappe.boot.socketio_port || port.toString() || '3000'
        if (parts.length > 2) {
          host = `${parts[0]}:${parts[1]}`
        }
        host = `${host}:${port}`
      }
      return host
    },
  },
}
</script>

<style scoped>
.sidebar {
  margin: 0;
  padding: 0;
  /* width: 400px; */
  height: 75vh
}

/* Page content. The value of the margin-left property should match the value of the sidebar's width property */

content {
  margin-left: 10px;
  margin-right: 10px;
  padding: 1px 4px;
  max-height: calc(75vh - 0.01rem);
  margin-top: 0px;
}

/* On screens that are less than 700px wide, make the sidebar into a topbar */

@media screen and (max-width: 700px) {
  .sidebar {
    width: 100%;

    position: relative;
  }

  .sidebar a {
    float: left;
  }

  div.content {
    margin-left: 0;
  }
}

/* On screens that are less than 400px, display the bar vertically, instead of horizontally */

@media screen and (max-width: 400px) {
  .sidebar a {
    text-align: center;
    float: none;
  }
}

.main {
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
<style  scoped>
.custom-tabs {
  .card-header {
    background-color: #fff;

    ul {
      li {
        margin: 2px;
        display: flex;
        flex-direction: column;
        flex: 1 0;
      }
    }
  }

  .card-footer {
    min-height: 60px;
  }

  .card-body {
    padding: 0px !important;
  }

  .card-header {
    padding: 0px !important;
  }

}


@media only screen and (max-width: 767px) {
  .nav-tabs {
    .nav-link {
      border: none;
      padding: 0.61rem 0.6rem !important;
      border-radius: 0;
    }
  }

  div {
    font-size: 11px !important;
  }

  .control-view-wrapper {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }

  .control-list-container {
    padding: 0px 9px !important;
  }
}
</style>
