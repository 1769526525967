import useJwt from '@/auth/jwt/useJwt'

export const api = ({ method, args = {}, freeze = true }) => new Promise((resolve, reject) => useJwt.api(method, args)
  .then(response => resolve(response.data.message))
  .catch(error => reject(error)))

export const getPatientPrescriptionsV2 = ({
  patient_name,
  encounter_name,
  ...restArgs
}) => api({
  method: 'clinical.api.prescription.prescription.get_patient_prescriptions',
  freeze: false,
  args: {
    patient_name,
    encounter_name,
    ...restArgs,
  },
})

export const addADrug = args => api({
  method: 'clinical.clinical.doctype.drug.drug.add_a_drug',
  args,
})

export const getPatientInfo = patient_name => api({
  method: 'clinical.api.patients.get_patient_details',
  args: { patient_name },
})

export const getPatientUnallocatedBalance = patient => api({
  method: 'billing.billing.api.payment_integration.process_payment.get_unallocated_payment',
  args: {
    patient,
  },
})
