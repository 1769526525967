<template>
  <div class="w-100">

    <b-row class="w-100">
      <b-col
        cols="3"
        style="padding: 0px !important"
      >
        <div class="sidebar">
          <SideBar
            class="w-100"
            @serviceUnitSelected="serviceUnitSelectedSidebar"
            @patient-selected="patientClicked"
          />
        </div>
      </b-col>
      <b-col
        cols="9"
        style="padding: 0px !important"
      >
        <div class="w-100 p-1">
          <MedicationList
            class="w-100"
            :selected-pharmacy="selectedPharmacy"
            :patient="activePatient"
          />
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import SideBar from './SideBar.vue'
import MedicationList from './MedicationList.vue'

export default {
  name: 'InpatientMedications',
  components: {
    // PatientList,
    SideBar,
    MedicationList,
  },
  props: {
    selectedPharmacy: {
      type: String,
      default: '',
      required: true,
    },
  },

  data() {
    return {
      testData: 'test PatientList',
      activePatient: {},
    }
  },
  mounted() {
    // this.makeFields();
  },
  methods: {
    patientClicked(patient) {
      this.activePatient = patient
      // this.$emit("patient-selected", patient);
    },
    serviceUnitSelectedSidebar(serviceUnit) {
    //   alert(serviceUnit);
    },
  },
}
</script>
<style scoped>
.sidebar {
  margin: 1%;
  padding: 1%;
  /* width: 400px; */
  height: calc(75vh - 0.01rem);
}

/* Page content. The value of the margin-left property should match the value of the sidebar's width property */

content {
  margin-left: 10px;
  margin-right: 10px;
  padding: 1px 4px;
  max-height: calc(100vh - 0.01rem);
  margin-top: 0px;
}

/* On screens that are less than 700px wide, make the sidebar into a topbar */

@media screen and (max-width: 700px) {
  .sidebar {
    width: 100%;
    height: auto;
    position: relative;
  }

  .sidebar a {
    float: left;
  }

  div.content {
    margin-left: 0;
  }
}

/* On screens that are less than 400px, display the bar vertically, instead of horizontally */

@media screen and (max-width: 400px) {
  .sidebar a {
    text-align: center;
    float: none;
  }
}

.main {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
<style  scoped>
.custom-tabs {
  .card-header {
    background-color: #fff;

    ul {
      li {
        margin: 2px;
        display: flex;
        flex-direction: column;
        flex: 1 0;
      }
    }
  }

  .card-footer {
    min-height: 60px;
  }
}
</style>
