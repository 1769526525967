<template>
  <div
    class=""
    style="margin-left: 0.1%; background-color: #ffffff; overflow: scroll; height: 75vh;"
  >
    <!-- <b-modal id="modal-prevent-closing" ref="changeServiceUnitModal" title="Select Pharmacy Warehouse" @hidden="resetServiceUnits" @ok="updateWarehouse">
                  <b-form-select v-model="warehouse" :options="warehouses" placeholder="Select warehouse"></b-form-select>
                  <div class="mt-3">Selected: <strong>{{ warehouse }}</strong></div>
              </b-modal> -->
    <!-- <div v-if="loading" style="padding-top:25%; text-align:center; height:100vh;">
                  <b-spinner style="width: 300px; height: 300px;margin:auto;"></b-spinner>
              </div> -->
    <!-- v-else -->
    <div
      style="
          padding-top: 1%;
          margin-top: 1px;
          margin-left: 16px;
          margin-right: 16px;
          align-content: center;
          padding-left: 8px;
          padding-right: 8px;
        "
    >

      <b-row
      v-if="patientData && patientData.patient_name"
        class="w-100 "
        style="border: 0px solid lightgrey; border-radius: 5px; padding:2px; "
      >

        <b-table
      
          small
          hover
          :items="[1]"
          :fields="fields"
          bordered
          stacked="sm"
          class="table-light"
        
          style="border: 1px solid lightgrey; margin-bottom: 0px !important"
        >
          <template v-slot:cell(column1)="data">
            <h6>
              {{ patientData.patient_name }}
              [{{ patientData.name }}]
            </h6>
          </template>
          <template v-slot:cell(column2)="data">
            <!-- Custom template for Column 2 -->
            <span>{{
              patientData.customer_group === 'All Customer Groups' ? 'CASH PAYER' : patientData.customer_group
            }}</span>
          </template>
          <template v-slot:cell(column3)="data">
            {{ patientSummary.height }} (M)

          </template>
          <template v-slot:cell(column4)="data">
            {{
              patientSummary.weight }} Kgs.
          </template>
          <template v-slot:cell(column5)="data">
            <b-button
              style="margin-right: 2px"
              size="sm"
              :variant="`${patientBalance < 1 ? 'outline-secondary' : 'success'}`"
            >
              Balance: Kes {{ patientBalance }} </b-button>
            <b-button
              style="margin-right: 2px"
              size="sm fi"
              variant="primary"
              @click="addDrugModal"
            >Add
              Drug</b-button>
          </template>
        </b-table>

      </b-row>
      <b-row class="w-100">
        <div
          v-if="patientName"
          style="margin-top: 8px; padding: 0px"
          class="w-100"
        >
          <b-tabs
            v-model="tab"
            content-class="note-tabs-content"
            class="w-100"
            small
            style="border: 1px solid lightgrey; border-radius: 5px;"
          >
            <b-tab
              title="Prescriptions"
              class="p-1"
            >
              <UserPrescription
                v-if="tab === 0"
                :queue-state="queueState"
                :patient-name="patientName"
                :encounter-name="encounterName"
                :prescription="prescription"
                :patient-data="patientData"
                :selected-pharmacy="selectedPharmacy"
                @onUpdateList="onUpdateList()"
              />
            </b-tab>
            <b-tab
              title="Staged Prescriptions"
              class="p-1"
            >
              <AwaitingDispense
                v-if="tab === 1"
                :queue-state="queueState"
                :patient-number="patientName"
                :selected-pharmacy="selectedPharmacy"
              />
            </b-tab>

            <b-tab
              title="Charge Sheet"
              class="p-1"
            >
              <ChargeSheet
                v-if="tab === 2"
                :patient="patientName"
              />
            </b-tab>

            <b-tab
              title="Charge Sheet List"
              class="p-1"
            >
              <ChargeSheetList
                v-if="tab === 3"
                :patient="patientName"
              />
            </b-tab>

            <b-tab
              title="Payments"
              class="p-1"
            >
              <Receipts
                v-if="tab === 4"
                :patient_number="patientName"
                :patient-name="patientName"
              />
            </b-tab>
            <b-tab title="Refills">
              <Refills
                v-if="tab === 5"
                :patient_number="patientName"
              />
            </b-tab>
            <!-- <b-tab title="Sales Orders">
                  <SalesOrder v-if="tab === 6" :patient_number="patientName" />
                </b-tab>
                <b-tab title="Sales Invoice">
                  <Invoice v-if="tab === 7" :patient_number="patientName" />
                </b-tab> -->

          </b-tabs>
        </div>
        <div
          v-else
          class="center-image"
        >
          <img
            src="https://cdn.dribbble.com/users/1449854/screenshots/4136663/no_data_found.png"
            alt="No data IMG"
          >
        </div>
      </b-row>

      <!-- listen for payment invoice frontend -->
      <span>
        <payment-notification-vue :patient="patientData.patient_name" />
      </span>

      <b-row class="mt-2 mb-2">
        <b-col>
          <div class="d-flex flex-column mb-6 bg-surface-variant">
            <v-sheet class="ma-2 pa-2">
              <b-alert
                v-model="showDismissibleAlert"
                variant="info"
                dismissible
              >
                {{ response_message }}
              </b-alert>

              <b-alert
                v-model="showDismissibleAlertSuccess"
                variant="success"
                dismissible
              >
                {{ response_message }}
              </b-alert>

              <b-alert
                v-model="showDismissibleAlertError"
                variant="danger"
                dismissible
              >
                {{ response_message }}
              </b-alert>
            </v-sheet>
          </div>
        </b-col>
      </b-row>

    </div>

  </div>
</template>
<script>

import PatientInfo from '@/views/erp/clinical/working-area/components/patient-info/PatientInfo.vue'
import customerModule from '@/views/erp/state/pharmacy-customer'
import prescriptionModule from '@/views/erp/state/pharmacy-prescription'
import Paginator from './components/util/Paginator.vue'
import UserPrescription from './components/prescriptions/UserPrescription.vue'
import Refills from './components/Refills.vue'
import AddPrescription from './components/AddPrescription2.vue'
import AwaitingDispense from './components/AwaitingDispense.vue'
import PaymentNotification from '../../clinical/working-area/components/patient-info/components/payment-notification/PaymentNotification.vue'
import ChargeSheet from '../../billing/components/chargesheet/ChargeSheet.vue'
import ChargeSheetList from '../../billing/components/chargesheet/ChargeSheetList.vue'
import Receipts from '../../billing/components/receipts/Receipts.vue'

import {
  getPatientPrescriptionsV2,
  addADrug,
  getPatientInfo,
  getPatientUnallocatedBalance,
  api,
} from './service'

export default {
  components: {
    Paginator,
    PatientInfo,
    UserPrescription,
    Refills,
    AddPrescription,
    AwaitingDispense,
    PaymentNotification,
    // ChargeSheet,
    ChargeSheet,
    Receipts,
    ChargeSheetList,
  },
  filters: {
    // Filter definitions
    Upper(value) {
      return value.toUpperCase()
    },
    title(str) {
      return str.replace(/\w([^-\s]*)/g, txt => (txt || '').charAt(0).toUpperCase() + txt.substr(1).toLowerCase())
    },
    numberWithCommas(x) {
      if (x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return 0
    },
  },
  props: {
    queueState: {
      type: Object,
      default: {},
      required: true,
    },
    patientName: {
      type: String,
      default: '',
      required: true,
    },
    encounterName: {
      type: String,
      default: '',
      required: true,
    },
    selectedPharmacy: {
      type: String,
      default: '',
      required: true,
    },
  },
  data() {
    return {
      fields: [
        { key: 'column1', label: 'Name' },
        { key: 'column2', label: 'Scheme' },
        { key: 'column3', label: 'Height' },
        { key: 'column4', label: 'Weight' },
        { key: 'column5', label: 'Actions' },
      ],
      prescription: {},
      socket: null,
      tab: 0,
      patientData: {},
      patientSummary: {},
      patientBalance: 0,
      showDismissibleAlert: false,
      showDismissibleAlertError: false,
      showDismissibleAlertSuccess: false,
      response_message: '',
    }
  },
  computed: {
    customerInfo() {
      return this.$store.getters['customerModule/customerInfo']
    },
    prescription() {
      return this.$store.getters['prescriptionModule/prescription']
    },
  },
  watch: {
    queueState() {
      this.socket.on(this.queueState.name, function (data) {
        console.log('queueState event connected')
        this.getPrescriptionDrugs(
          this.queueState.patient,
          this.queueState.appointment,
        )
      })
      // console.log('****', this.queueState.patient)
    },
    patientName() {
      this.loadPatientDetails()
    },
    customerInfo() {
      console.log(this.customerInfo)
    },
    prescription() {
      this.totalAmount = Math.ceil(
        this.prescription.reduce(
          (accum, item) => accum + (item.unit_price * item.qty || 1),
          0,
        ),
      ).toFixed(2)
    },

  },
  created() {
    this.$store.registerModule('customerModule', customerModule)
    this.$store.registerModule('prescriptionModule', prescriptionModule)
    // this.getPrescriptionDrugs(this.queueState.patient, this.queueState.appointment);
    // this.socket.on(this.queueState.name, function(data) {
    //     console.log('queueState eventprescriptionModule connected')
    //     this.getPrescriptionDrugs(this.queueState.patient, this.queueState.appointment);
    // });
  },
  mounted() {
    this.loadPatientDetails()
    const { socket } = window
    this.socket = socket
    socket.on('connect', () => {
      console.log('parent connected')
    })
    socket.on(this.queueState.name, function (data) {
      console.log('queueState event connected')
      this.getPrescriptionDrugs(
        this.queueState.patient,
        this.queueState.appointment,
      )
    })
  },
  methods: {

    loadPatientDetails() {
      if (this.patientName) {
        getPatientInfo(this.patientName).then(d => {
          this.patientSummary = d.summary.vitals || {}
        })

        api({
          method: 'frappe.client.get',
          args: {
            doctype: 'Patient',
            name: this.patientName,
          },

        }).then(r => {
          this.patientData = r
        })

        getPatientUnallocatedBalance(this.patientName).then(
          res => {
            this.patientBalance = res
          },
        )
      }
    },
    makeToast(variant = null) {
      this.$bvToast.toast(this.response_message, {
        title: 'Mpesa',
        variant,
        solid: true,
      })
    },
    verifyMpesaPayment() {
      this.showDismissibleAlert = false
      this.showDismissibleAlertError = false

      try {
        window.socket.off(patient_no)
      } catch (err) {
        console.log(err.message)
      }

      // let note = "(NB: This will check for payments from Safaricoms's End)";
      // this.msgprint({ message:note ,indicator: 'blue'}, 2);
      const patient_no = this.queueState.patient

      // console.log(this.patient_number);
      const parent = this
      const dialog = new frappe.ui.Dialog({
        title: `Verify Mpesa Payment for ${parent.patientName} `,
        fields: [{
          label: 'Name',
          fieldname: 'party',
          fieldtype: 'Read Only',
          options: 'Customer',
          default: `${parent.patientName} (${parent.customerNumber})`,
          readonly: 0,
        },
        {
          label: 'Transaction Code',
          fieldname: 'transaction_code',
          fieldtype: 'Data',
          reqd: 1,
        },
        ],
        primary_action_label: 'Check Transaction',
        primary_action(values) {
          // save payment entry
          // console.log(JSON.stringify(dialog.get_values()));
          if (values.transaction_code) {
            console.log(patient_no)
            console.log(values.transaction_code.toUpperCase())
            this.msgprint({
              message: 'checking please Wait...',
              indicator: 'green',
            }, 2)
            api({
              method:
                                'billing.billing.api.payment_integration.mpesa_payment_verify.initiate_mpesa_verification',
              args: {
                patient_number: patient_no,
                transaction_code: values.transaction_code.toUpperCase(),
              },
              freeze: false,
            }).then(r => {
              console.log(r.message)
              // console.log('dialog state '+ parent.showDismissibleAlert);

              if ('ResponseCode' in r.message) {
                if (r.ResponseCode && r.ResponseCode === '0') {
                  //     this.msgprint({
                  //     message: 'please Wait...',
                  //     indicator: 'green'
                  // }, 3);

                  // subscribe to channel
                  window.socket.on(patient_no, data => {
                    // this.msgprint({  message: data.body,indicator: 'green' }, 55);
                    parent.response_message = data.body
                    if (data.title === 'No Payment Found') {
                      parent.showDismissibleAlertError = true
                      parent.makeToast('danger')
                    } else if (data.title === 'success') {
                      parent.showDismissibleAlertSuccess = true
                      parent.makeToast('success')
                    } else {
                      parent.showDismissibleAlert = true
                      parent.makeToast('info')
                    }

                    console.log(data)

                    window.socket.off(patient_no)
                    // console.log('Realtime Watcher off '+ patient_no);
                    parent.getPayments()
                  })
                } else {
                  this.msgprint({
                    message: 'Failed, please Try Again',
                    indicator: 'red',
                  }, 3)
                }
              }

              // payment alreadly done
              if ('Duplicate' in r) {
                parent.response_message = r.Duplicate
                parent.showDismissibleAlert = true
                parent.makeToast('info')
              }

              // process stalled payment
              if ('Process' in r) {
                parent.response_message = r.Process
                parent.showDismissibleAlertSuccess = true
                parent.makeToast('success')
                parent.getPayments()
              }

              if ('Error' in r) {
                parent.response_message = r.Error
                parent.showDismissibleAlertError = true
                parent.makeToast('danger')
                parent.getPayments()
              }

              dialog.hide()
            })
          } else {
            dialog.hide()
            this.msgprint({
              message: 'Enter A Valid Transaction Id',
              indicator: 'red',
            }, 5)
          }
        },
        secondary_action_label: 'Cancel',
        secondary_action() {
          dialog.hide()
          window.socket.off(patient_no)
          // console.log('Realtime Watcher off '+ patient_no);
        },
      })

      dialog.show()

      // frappe.require("/assets/frontend/js/mpesa_dialog.js", () => {
      //   frappe.db
      //     .get_value("Patient", this.queueState.patient, "customer")
      //     .then(({ message }) => {
      //       const customer = message.customer;
      //       const callback = () => {
      //         updateQueueStatus({ state_name: this.queueState.name, status: 'Payment Completed' }).then(m => { });
      //       }
      //       mpesaDialog(customer, this.queueState.patient, callback);
      //     });
      // });
    },
    pullFunsoftReceipt() {
      frappe.require('/assets/frontend/js/mpesa_dialog.js', () => {
        // chat.js is loaded
        console.log(b)
        funsoftBankReceipt(this.queueState.patient)
      })
    },
    addDrugModal() {
      const fields = [
        {
          fieldname: 'item',
          fieldtype: 'Link',
          in_list_view: 1,
          label: 'Item',
          reqd: 1,
          options: 'Item',
        },
        {
          fetch_from: 'item.item_name',
          fieldname: 'item_name',
          fieldtype: 'Data',
          label: 'Item Name',
          read_only: 1,
        },
        {
          fetch_from: 'item.stock_uom',
          fieldname: 'uom',
          fieldtype: 'Link',
          label: 'UOM',
          options: 'UOM',
          read_only: 1,
        },
        {
          fieldname: 'dosage_form',
          fieldtype: 'Link',
          label: 'Dosage Form',
          reqd: 1,
          in_list_view: 1,
          options: 'Dosage Form',
        },
        {
          fieldname: 'strength',
          fieldtype: 'Data',
          in_list_view: 1,
          reqd: 1,
          label: 'Strength',
        },
      ]
      const dialog = new frappe.ui.Dialog({
        title: 'Add a Drug',
        fields: [
          {
            fieldname: 'medicine_name',
            fieldtype: 'Data',
            in_list_view: 1,
            label: 'Medicine Name',
            unique: 1,
          },
          {
            fieldname: 'drug_stock_detail',
            fieldtype: 'Table',
            label: 'Drug Stock Detail',
            options: 'Drug Stock Detail',
            fields,
          },
        ],
        primary_action: values => {
          addADrug(values).then(() => {
            dialog.hide()
            this.msgprint({
              message: 'Drug Added Succesfully',
              indicator: 'green',
            }, 10)
          })
        },
      })
      dialog.show()
      //   dialog.$wrapper.find('.modal-dialog').css("width", "800px");
    },
    onUpdateList() {
      this.getPrescriptionDrugs(
        this.queueState.patient,
        this.queueState.appointment,
      )
    },
    getPrescriptionDrugs(patient, encounter) {
      getPatientPrescriptionsV2({
        patient_name: patient,
        encounter_name: encounter,
        fetch: 'all',
        // appointment_name:appointment
      }).then(result => {
        if (result) {
          // result.map((item) => {
          //     fetchConditionalItemPrice({
          //         item_code: item.drug_code,
          //         patient: patient,
          //     }).then(res => {

          //         if (res.type === "Patient") {
          //             item['rate'] = 0;
          //             item['rate'] = res.price;
          //             item['payer'] = res.type;

          //         } else if (res.type === "Copay") {
          //             item['rate'] = res.price;
          //             item['remaining_quantity'] = res.remaining_quantity;
          //             item['co_paid_quantity'] = res.co_paid_quantity;
          //             item['payer'] = res.type;

          //         } else if (res.type === "Insurance") {
          //             item['rate'] = res.price;
          //             item['payer'] = res.type;

          //         } else if (res.type === "Pre-auth Insurance") {
          //             item['rate'] = res.price;
          //             item['payer'] = res.type;

          //         } else if (res.type === "Pre-auth Copay") {
          //             item['rate'] = res.price;
          //             item['remaining_quantity'] = res.remaining_quantity;
          //             item['co_paid_quantity'] = res.co_paid_quantity;
          //             item['payer'] = res.type

          //         }
          //     })

          // })
          this.prescription = result
          // this.loading = false;
        } else {
          this.msgprint(
            {
              message: 'Request Failed. Please try again',
              indicator: 'red',
            },
            5,
          )
        }
      })
      // this.$store.dispatch("prescriptionModule/fetchPrescription", { patient, encounter, });
      this.$store.dispatch('customerModule/fetchCustomerInfomation', patient)
    },
    getConditionalItemPrice(item) { },
    get_host(port = 3000) {
      let host = window.location.origin
      if (window.dev_server || true) {
        const parts = host.split(':')
        port = frappe.boot.socketio_port || port.toString() || '3000'
        if (parts.length > 2) {
          host = `${parts[0]}:${parts[1]}`
        }
        host = `${host}:${port}`
      }
      return host
    },
  },
}
</script>

<style scoped>
th {
    background-color: white !important;
}
.center-image {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10%;
    height: 100%;
    width: 100%;
    vertical-align: middle;
}

.center-image img {
    width: 300px;
    height: 350px;
    top: 20%;
}

.patient-name {
    text-transform: capitalize;
}

.note-tabs-content {
    padding: 10px;
    border: none;
    color: #676a6c;
}

th {
    border-bottom: 2px solid black;
}

.custom-tabs {
    .card-header {
        background-color: #fff;

        ul {
            li {
                margin: 2px;
                display: flex;
                flex-direction: column;
                flex: 1 0;
            }
        }
    }

    .card-footer {
        min-height: 60px;
    }
}

@media only screen and (max-width: 767px) {
 
  div {
    font-size: 11px !important;
  }
  
  }
</style>
