export default {
  // warehouses: state => state.warehouses,
  // servicePoints: state => state.servicePoints,
  // selectedWarehouse: state => state.selectedWarehouse,
  // selectedServicePoint: state => state.selectedServicePoint,
  warehouses: state => state.warehouses,
  servicePoints: state => state.servicePoints,
  selectedServicePoint: state => state.selectedServicePoint,
  selectedServicePoint: state => state.selectedServicePoint,
}
