export default {
  SET_SERVICE_POINTS(state, servicePoints) {
    state.servicePoints = servicePoints
  },
  SET_WAREHOUSES(state, warehouses) {
    state.warehouses = warehouses
  },
  SET_SELECTED_WAREHOUSE(state, wh) {
    state.selectedWarehouse = wh
  },
  SET_SELECTED_SERVICE_POINT(state, sp) {
    state.selectedServicePoint = sp
  },
}
