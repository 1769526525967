import {
  getUserWarehouses,
  getWarehouseServicePoints,
} from './service'

export default {
  fetchWarehouseServicePoints({ commit }, payload) {
    getWarehouseServicePoints({ warehouse_name: payload }).then(result => {
      if (result && result.length > 0) {
        // this.setServicePoint(result[0]) SET_SELECTED_SERVICE_POINT
        commit('SET_SERVICE_POINTS', result)
        commit('SET_SELECTED_SERVICE_POINT', result[0])
      } else {
        frappe.msgprint({
          message: 'No service Point(s) for this warehoue.',
          indicator: 'red',
        }, 5)
      }
    })
  },
  fetchUserWarehouses({ commit }, payload = '') {
    getUserWarehouses({
      user_name: this.getUserSession().user,
    }).then(result => {
      // console.log(JSON.stringify(result))
      if (result && result.length > 0) {
        commit('SET_WAREHOUSES', result)
        commit('SET_SELECTED_WAREHOUSE', result[0])
      } else {
        frappe.msgprint({
          message: 'You are not assigned to any warehouse.\nContact Support for help.',
          indicator: 'red',
        }, 5)
      }
    })
  },
}
