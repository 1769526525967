import useJwt from '@/auth/jwt/useJwt'

export const api = ({ method, args = {}, freeze = true }) => new Promise((resolve, reject) => useJwt.api(method, args)
  .then(response => resolve(response.data))
  .catch(error => reject(error)))
export const incomingMateriallRequests = ({
  warehouse_name,
}) => api({
  method: 'clinical.api.warehouse.warehouse.get_incoming_material_request',
  args: {
    warehouse_name,
  },
}).then(({ message }) => message)

export const outgoingMateriallRequests = ({
  warehouse_name,
}) => api({
  method: 'clinical.api.warehouse.warehouse.get_outgoing_material_request',
  args: {
    warehouse_name,
  },
}).then(({ message }) => message)

export const materiallRequestItems = ({
  request_name,
}) => api({
  method: 'clinical.api.warehouse.warehouse.get_material_request_items',
  args: {
    request_name,
  },
}).then(({ message }) => message)

export const materiallRequestStockEntry = ({
  request_name,
}) => api({
  method: 'clinical.api.warehouse.warehouse.material_request_transfer_stock_entry',
  args: {
    request_name,
  },
}).then(({ message }) => message)

export const submitMateriallRequestS = ({
  request_name,
}) => api({
  method: 'clinical.api.warehouse.warehouse.submit_material_request',
  args: {
    request_name,
  },
}).then(({ message }) => message)

export const itemStockBalance = ({
  item_code,
}) => api({
  method: 'clinical.api.warehouse.warehouse.get_item_stock_quantities',
  args: {
    item_code,
  },
}).then(({ message }) => message)

// create_material_request(item_code,qty,warehouse,from_warehouse)
export const createMaterialRequest = ({
  item_code,
  qty,
  warehouse,
  from_warehouse,
}) => api({
  method: 'clinical.api.warehouse.warehouse.create_material_request',
  args: {
    item_code,
    qty,
    warehouse,
    from_warehouse,
  },
}).then(({ message }) => message)
