<template>
  <b-container
    class="px-2"
    style="margin-top: 5px;"
  >
    <b-row
      class="w-100"
      align-v="center"
    >
      <h5 class="text-primary">
        {{ selectedWarehouse.warehouse }} {{ selectedServicePoint != null ? ` > ${selectedServicePoint.point_name}` : '' }}
      </h5> <b-button
        class="ml-1"
        size="sm"
        variant="outline-secondary"
        style="border: 0px solid black; !important"
        @click="showModal = true"
      > <i
        class="fa-solid fa-pen-to-square"
        size="sm"
      /></b-button>

    </b-row>

    <b-modal v-model="showModal">
      <b-row class="w-100">
   
        <b-form-select
          v-model="selectedWarehouse"
          class="w-20"
          size="sm"
        >
          <b-form-select-option
            v-for="warehouse in warehouses"
            :key="warehouse"
            :value="warehouse"
            @click="selectWarehouse(warehouse.warehouse)"
          >{{ warehouse.warehouse
          }}</b-form-select-option>

        </b-form-select>
        <h5 class="mt-1">
          SERVICE POINT:
        </h5>
        <b-form-select
          v-model="selectedServicePoint"
          size="sm"
        >
          <b-form-select-option
            v-for="servicePoint in servicePoints"
            :key="warehouse"
            :value="servicePoint"
            @click="selectServicePoint(servicePoint.point_name)"
          >{{
            servicePoint.point_name
          }}</b-form-select-option>

        </b-form-select>
      </b-row>
    </b-modal>

  </b-container>
</template>

<script>

import {
  getUserWarehouses,
  getWarehouseServicePoints,
} from '../working-area/components/prescriptions/service'

export default {
  name: 'TopHeader',
  props: {
    servicePoint: {
      type: Object,
      required: true,
      default: {},
    },
  },
  data() {
    return {
      socket: null,
      showModal: false,
      warehouses: [],
      selectedWarehouse: null,
      servicePoints: null,
      selectedServicePoint: null,
    }
  },
  computed: {
    notificationList() {
      return this.$store.getters['notification/getNotificationList']
    },
  },
  watch: {
    selectedWarehouse() {
      getWarehouseServicePoints({ warehouse_name: this.selectedWarehouse.warehouse }).then(result => {
        if (result && result.length > 0) {
          this.servicePoints = result
          this.setServicePoint(result[0])
        } else {
          this.msgprint({
            message: 'No service Point(s) for this warehouse.',
            indicator: 'red',
          }, 5)
        }
      })
    },
  },
  created() { },
  mounted() {
    const { user } = this.getUserSession()
    this.getWarehouses()
    this.getNotifications(user)
  },
  methods: {
    selectServicePoint() {
      const parent = this
      const d = new frappe.ui.Dialog({
        title: `Select Service Point for ${parent.selectedWarehouse.warehouse.replace(/^\w/, c => c.toUpperCase())}`,
        fields: [{
          label: 'Service Point',
          fieldname: 'service_point',
          fieldtype: 'Select',
          options: parent.servicePoints.map(w => w.point_name),
        }],
        primary_action_label: 'Select',
        primary_action(values) {
          const servicePoint = parent.servicePoints.find(el => el.point_name === values.service_point.replace(/^\w/, c => c.toUpperCase()))
          parent.setServicePoint(servicePoint)
          d.hide()
        },
        secondary_action_label: 'Cancel',
        secondary_action() {
          d.hide()
        },
      })

      d.show()
    },
    selectWarehouse() {
      const parent = this
      const d = new frappe.ui.Dialog({
        title: 'Select Pharmacy',
        fields: [{
          label: 'Pharmacy',
          fieldname: 'pharmacy',
          fieldtype: 'Select',
          options: parent.warehouses.map(w => w.warehouse),
        }],
        primary_action_label: 'Select',
        primary_action(values) {
          // let warehouse = parent.warehouses.find(el => el.warehouse === values.pharmacy.replace(/^\w/, c => c.toUpperCase()));
          const warehouse = parent.warehouses.find(el => el.warehouse.replace(/^\w/, c => c.toUpperCase()) === values.pharmacy.replace(/^\w/, c => c.toUpperCase()))
          parent.setWarehouse(warehouse)
          d.hide()
        },
        secondary_action_label: 'Cancel',
        secondary_action() {
          d.hide()
        },
      })

      d.show()
    },
    refresh() {
      if (this.selectedWarehouse && this.selectedServicePoint) {
        this.$emit('warehouseSelected', this.selectedWarehouse)
        this.$emit('selectedServicePoint', this.selectedServicePoint)
      }
    },
    setWarehouse(warehouse) {
      this.selectedWarehouse = warehouse
      this.$emit('warehouseSelected', warehouse)
    },
    setServicePoint(servicePoint) {
      this.selectedServicePoint = servicePoint
      this.$emit('selectedServicePoint', servicePoint)
    },
    getWarehouses() {
      getUserWarehouses({
        user_name: this.getUserSession().user,
      }).then(result => {
        if (result && result.length > 0) {
          this.warehouses = result
          this.setWarehouse(result[0])
          this.loading = false
        } else {
          this.msgprint({
            message: 'You are not assigned to any warehouse.\nContact Support for help.',
            indicator: 'red',
          }, 5)
        }
      })
    },
    getNotifications(user) {
      const payload = { ref: user }
      this.$store.dispatch('notification/fetchNotifications', payload)
    },

    clearNotification(notification) {
      const { name } = notification
      const payload = { name, user: this.getUserSession().user }
      this.$store.dispatch('notification/clearNotification', payload)
      this.setCurrentDoctype(
        notification.reference_doctype,
        notification.doctype_reference,
      )
    },

    setCurrentDoctype(doctype, reference) {
      this.selectedDoctype = doctype
      this.selectedReference = reference
      if (reference && doctype) {
        this.$router.push({ name: 'viewer', params: { doctype, reference } })
      }

      // this.$refs["my-modal-1993"].show();
    },

  },
}
</script>

<style scoped>
.dropdown {
    /* width: 200px; */
    margin-left: 8px;
    margin-right: 8px;
    text-transform: capitalize;
    font-weight: 800;
}

.warehouse-dropdown {
    width: 600px;
    margin-left: 16px;
    align-items: center;
    display: flex;
    /* justify-content: center; */
    /* justify-content: space-between; */
}

#page-patient-chart {
    height: 100vh;
}

.row {
    margin: 0px !important;
}

.notification-badge {
    position: absolute;
    margin-left: -9px;
    top: -3px;
}
</style>
