import useJwt from '@/auth/jwt/useJwt'

export const api = ({ method, args = {}, freeze = true }) => new Promise((resolve, reject) => useJwt.api(method, args)
  .then(response => resolve(response.data))
  .catch(error => reject(error)))
export const getPrescriptionList = ({
  start = 0,
  page_length = 20,
  patient = '',
}) => api({
  method: 'clinical.clinical.page.prescription_page.prescription_page.get_prescriptions_items',
  args: {
    start,
    page_length,
    patient,
  },
}).then(({ message }) => message)

export const getPrescriptions = ({
  start = 0,
  page_length = 20,
  warehouse,
}) => api({
  method: 'clinical.api.prescription.prescription.get_prescriptions',
  args: {
    start,
    page_length,
    warehouse,
  },
}).then(({ message }) => message)

export const getPrescriptionsV2 = ({
  start = 0,
  page_length = 20,
  warehouse_name,
}) => api({
  method: 'clinical.api.prescription.prescription.get_warehouse_prescriptions',
  args: {
    start,
    page_length,
    warehouse_name,
  },
}).then(({ message }) => message)

export const getPatientPrescriptionsV2 = ({
  patient_name,
  encounter_name,
  ...restArgs
}) => api({
  method: 'clinical.api.prescription.prescription.get_patient_prescriptions',
  freeze: false,
  args: {
    patient_name,
    encounter_name,
    ...restArgs,
  },
}).then(({ message }) => message)

export const updatePrescriptionItem = ({
  name,
  qty,
}) => api({
  method: 'clinical.api.prescription.prescription.update_prescription_item',
  args: {
    name,
    qty,
  },
}).then(({ message }) => message)

export const createPrescriptionInvoice = ({
  patient,
  grand_total,
  encounter_names,
  appointment_name,
}) => api({
  method: 'clinical.api.prescription.invoice.create_sales_invoice',
  args: {
    patient,
    grand_total,
    encounter_names,
    appointment_name,
  },
}).then(({ message }) => message)

export const getPrescriptionInvoice = ({
  name,
}) => api({
  method: 'clinical.api.prescription.invoice.get_prescription_invoice',
  args: {
    name,
  },
}).then(({ message }) => message)

export const setPrescriptionInvoiceNumber = ({
  prescription_name,
  invoice_name,
}) => api({
  method: 'clinical.api.prescription.invoice.set_prescription_invoice',
  args: {
    prescription_name,
    invoice_name,
  },
}).then(({ message }) => message)

export const getDrugItemAlternates = ({
  drug_name,
}) => api({
  method: 'clinical.api.prescription.prescription.get_drug_item_alternates',
  args: {
    drug_name,
  },
}).then(({ message }) => message)

export const updatePrescriptionItemDrug = ({
  prescription_item_name,
  drug_name,
  patient_name,
}) => api({
  method: 'clinical.api.prescription.prescription.update_prescription_item_drug',
  args: {
    prescription_item_name,
    drug_name,
    patient_name,
  },
}).then(({ message }) => message)

export const getPrecriptionDrugUnitPrice = ({
  drug_name,
  patient_name,
}) => api({
  method: 'clinical.clinical.doctype.prescription.utils.get_drug_unit_price',
  args: {
    drug_name,
    patient_name,
  },
}).then(({ message }) => message)

export const getHealthcareServiceUnits = ({

}) => api({
  method: 'clinical.clinical.page.prescription_page.prescription_page.get_healthcare_service_unit',
  args: {

  },
}).then(({ message }) => message)

export const updatePrescriptionWarehouse = ({
  prescription_name,
  warehouse_name,
}) => api({
  method: 'clinical.api.prescription.warehouse.update_prescription_warehouse',
  args: {
    prescription_name,
    warehouse_name,
  },
}).then(({ message }) => message)

export const searchPrescriptions = ({
  patient_name,
}) => api({
  method: 'clinical.api.prescription.prescription.search_prescriptions',
  args: {
    patient_name,
  },
}).then(({ message }) => message)

export const filterPrescriptions = ({
  start = 0,
  page_length = 20,
  filter,
}) => api({
  method: 'clinical.api.prescription.prescription.filter_prescriptions',
  args: {
    start,
    page_length,
    filter,
  },
}).then(({ message }) => message)

export const getWarehouses = ({

}) => api({
  method: 'clinical.api.warehouse.warehouse.get_warehouse_list',
  args: {

  },
}).then(({ message }) => message)

export const getUserWarehouses = ({
  user_name,
}) => api({
  method: 'clinical.api.warehouse.user_warehouse.get_user_warehouses',
  args: {
    user_name,
  },
}).then(({ message }) => message)

export const dispenseStockEntry = args => api({
  method: 'clinical.api.prescription.stock.prescription_dispense_stock_entry',
  args,
}).then(({ message }) => message)

export const prescriptionItemMaterialRequest = ({
  drug,
  quantity,
  warehouse,
  from_warehouse,
}) => api({
  method: 'clinical.api.prescription.stock.make_material_request',
  args: {
    drug,
    quantity,
    warehouse,
    from_warehouse,
  },
}).then(({ message }) => message)

export const prescriptionChangeComment = ({
  reference_doctype,
  reference_name,
  content,
  comment_email,
  comment_by,
}) => api({
  method: 'frappe.desk.form.utils.add_comment',
  args: {
    reference_doctype,
    reference_name,
    content,
    comment_email,
    comment_by,
  },
}).then(({ message }) => message)

export const customerInfomation = ({
  patient_name,
}) => api({
  method: 'clinical.api.prescription.customer.get_customer_group',
  args: {
    patient_name,
  },
}).then(({ message }) => message)

// export const createPrescriptionSalesOrder = (data) =>
//     api({
//         method: 'billing.billing.api.sales_orders.sales_order_endpoint.create_sales_order_endpoint',
//         args: {
//             data
//         },
//     });

export const getWarehouseServicePoints = ({
  warehouse_name,
}) => api({
  method: 'clinical.api.warehouse.user_warehouse.get_service_point_list',
  args: {
    warehouse_name,
  },
}).then(({ message }) => message)

export const createPrescriptionSalesOrder = ({
  prescription_docs,
}) => api({
  method: 'clinical.api.prescription.invoice.prescription_sales_order',
  args: {
    prescription_docs,
  },
}).then(({ message }) => message)

export const dispensePrescription = ({
  prescription_names,
  patient_name,
  warehouse_name,
}) => api({
  method: 'clinical.api.prescription.prescription.dispense_prescriptions',
  args: {
    prescription_names,
    patient_name,
    warehouse_name,
  },
}).then(({ message }) => message)

export const getPrescriptionRefills = ({
  patient_name,
}) => api({
  method: 'clinical.api.prescription.prescription.fetch_patient_refills',
  args: {
    patient_name,
  },
}).then(({ message }) => message)

export const updatePrescriptionRefillRate = ({
  refill_name,
}) => api({
  method: 'clinical.api.prescription.prescription.update_refill_rates',
  args: {
    refill_name,
  },
}).then(({ message }) => message)

export const updatePrescriptionRefillQuantity = ({
  refill_name,
  qty,
}) => api({
  method: 'clinical.api.prescription.prescription.update_refill_quantity',
  args: {
    refill_name,
    qty,
  },
}).then(({ message }) => message)

export const createRefillSalesOrder = ({
  refill_doc_name,
}) => api({
  method: 'clinical.api.prescription.invoice.refill_sales_order',
  args: {
    refill_doc_name,
  },
}).then(({ message }) => message)

export const dispensePrescriptionRefill = ({
  refill_name,
}) => api({
  method: 'clinical.api.prescription.prescription.dispense_prescriptions_refill',
  args: {
    refill_name,
  },
}).then(({ message }) => message)

export const getMatchedItems = args => api({
  method: 'clinical.api.orders.prescriptions.get_drug_options',
  args,
}).then(({ message }) => message)

export const matchStockItemApi = args => api({
  method: 'clinical.clinical.doctype.drug.drug.match_drug_to_stock_item',
  args,
}).then(({ message }) => message)

export const createMedicationOrder = order => api({
  method: 'clinical.api.inpatient_medication_orders.inpatient_medication_orders.create_inpatient_medication_order',
  args: {
    order,
  },
})
// get_drugs_awaiting_dispensation
export const createDrugSalesOrder = args => api({
  method: 'clinical.api.orders.prescriptions.create_a_drug_sales_order',
  args,
}).then(({ message }) => message)

export const getItemPriceListRate = ({
  item_code,
  customer,
  warehouse = '',
  qty = 1,
}) => api({
  method: 'billing.billing.api.sales_orders.sales_order_endpoint.get_price_list_rate',
  args: {
    item_code,
    customer,
    warehouse,
    qty,
  },
})
// stage_for_order
export const getDrugsAwaitingDispense = args => api({
  method: 'clinical.api.orders.prescriptions.get_drugs_awaiting_dispensation',
  args,
}).then(({ message }) => message)

export const getCurrentMedicationOrders = args => api({
  method: 'clinical.api.orders.prescriptions.get_inpatient_medication_orders_in_progress',
  args,
}).then(({ message }) => message)

export const stageItemForOrder = args => api({
  method: 'clinical.api.orders.prescriptions.stage_for_order',
  args,
}).then(({ message }) => message)

export const calculatePrescriptionQuantity = args => api({
  method: 'clinical.api.orders.prescriptions.calculate_prescription_qty',
  args,
}).then(({ message }) => message)

export const getDrugGroups = (args = {}) => api({
  method: 'clinical.api.orders.prescriptions.get_drug_item_groups',
  args,
}).then(({ message }) => message)

export const removeMatchedItem = args => api({
  method: 'clinical.clinical.doctype.drug.drug.remove_matched_item',
  args,
}).then(({ message }) => message)

export const addADrug = args => api({
  method: 'clinical.clinical.doctype.drug.drug.add_a_drug',
  args,
}).then(({ message }) => message)

export const updateDrugPrescription = (args = {}) => api({
  method: 'clinical.api.orders.prescriptions.edit_prescription',
  args,
}).then(({ message }) => message)

export const unStageApi = (args = {}) => {
  console.log(args)
  return api({
    method: 'clinical.api.orders.prescriptions.un_stage',
    args,
  }).then(({ message }) => message)
}

export const cancelOrderedPrescription = (args = {}) => api({
  method: 'clinical.api.orders.prescriptions.cancel_ordered_prescription',
  args,
})

export const cancelOrderReadyForDispense = (args = {}) => api({
  method: 'clinical.api.orders.prescriptions.cancel_prescription_ready_to_dispense',
  args,
})

export const getPatientInfo = patient_name => api({
  method: 'clinical.api.patients.get_patient_details',
  args: { patient_name },
})

export const getPatientBalance = customer_number => api({
  method: 'billing.billing.api.sales_invoice.create_sales_invoice.get_customer_balance',
  args: {
    customer_number,
  },
})

export const getPatientUnallocatedBalance = patient => api({
  method: 'billing.billing.api.payment_integration.process_payment.get_unallocated_payment',
  args: {
    patient,
  },
})

export const getMedicationOrders = entry => api({
  method: 'clinical.api.inpatient_drug_administration.inpatient_drug_administration.fetch_medication_orders',
  freeze: false,
  args: {
    entry,
  },
}).then(({ message }) => message)

export const calculateStockBalance = args => api({
  method: 'clinical.api.inpatient_drug_administration.inpatient_drug_administration.calculate_stock_balance',
  freeze: false,
  args,
}).then(({ message }) => message)

// dispatch_drug_to_store
export const dispatchMedicationOrder = args => api({
  method: 'clinical.api.inpatient_drug_administration.inpatient_drug_administration.dispatch_drug_to_ward',
  args,
}).then(({ message }) => message)

export const bulkEditMedicationOrders = args => api({
  method: 'clinical.api.inpatient_drug_administration.inpatient_drug_administration.bulk_change_medication_orders',
  args,
}).then(({ message }) => message)

export const cancelInpatientPrescriptions = name => api({
  method: 'clinical.api.orders.prescriptions.cancel_from_pharmacy',
  args: { name },
}).then(({ message }) => message)

export const getProcessedMedications = args => api({
  method: 'clinical.api.orders.prescriptions.get_processed_medications',
  args,
}).then(({ message }) => message)

export const undoOrderedprescriptionApi = args => api({
  method: 'clinical.api.orders.prescriptions.undo_ordered_prescription',
  args,
}).then(({ message }) => message)

export const splitPrescriptionsApi = args => api({
  method: 'clinical.api.orders.prescriptions.split_prescription',
  args,
}).then(({ message }) => message)

export const downloadPrescriptionsPDF = docname => api({
  method: 'clinical.api.orders.prescriptions.download_prescription_pdf',
  args: { docname },
}).then(({ message }) => message)

export const updateQueueStatus = ({
  state_name,
  status,
}) => api({
  method: 'clinical.clinical.doctype.queue_state.queue_state.update_queue_status',
  args: {
    state_name,
    status,
  },
}).then(({ message }) => message)

export const removeFromQueue = ({
  queue_state_name,
}) => api({
  method: 'clinical.clinical.doctype.queue_state.queue_state.remove_from_queue',
  args: {
    queue_state_name,
  },
}).then(({ message }) => message)
