<template>
  <b-container>
    <b-row
      style="width: 100%;"
      class="mt-1"
      align-h="between"
    >
      <UserWarehouse
        style="width: 30%;"
        @warehouse="setWarehouse"
      />

      <b-row style="width: 40%;">
        <b-col> <b-form-datepicker
          v-model="filters.from_date"
          size="sm"
          placeholder="From Date"
        /></b-col>
        <b-col> <b-form-datepicker
          v-model="filters.to_date"
          size="sm"
          placeholder="To Date"
          class="mb-2"
        /></b-col>

      </b-row>
    </b-row>
    <b-row class="my-1">
      <b-col>
        <div
          id="item"
          ref="item-search"
        />
      </b-col>

      <b-col>
        <div
          id="item-group"
          ref="item-group-search"
        />
      </b-col>
      <b-button
        :disabled="!this.warehouse"
        variant="primary"
        size="sm"
        class="mx-1 filter-button"
        @click="applyFilters()"
      > Apply</b-button>
      <b-button
        variant="danger"
        size="sm"
        class=" filter-button"
        @click="clearFilters()"
      >
        Clear
      </b-button>

    </b-row>

    <b-table
      id="my-table"
      striped
      outlined
      bordered
      head-row-variant="primary"
      sticky-header="65vh"
      responsive="sm"
      small
      :empty-text="`No items to show, click apply`"
      :empty-filtered-text="`No items to show`"
      :show-empty="true"
      :fields="fields"
      :per-page="perPage"
      :current-page="currentPage"
      :items="stockItems"
    >

      <template #cell(date)="row">
        <b-container v-if="row.item.date">
          <b-row v-if="row.item.date">
            {{ row.item.date.split(".")[0] }}
          </b-row>

        </b-container>
      </template>

      <template #cell(percent)="row">
        <b-container>
          <b-progress
            v-if="filters.item_code"
            striped
            :max="stockItems[stockItems.length - 1].qty_after_transaction - stockItems[0].qty_after_transaction"
            height="2rem"
          >
            <b-progress-bar
              :value="(stockItems[stockItems.length - 1].qty_after_transaction - stockItems[0].qty_after_transaction) - (stockItems[stockItems.length - 1].qty_after_transaction - row.item.qty_after_transaction)"
            >
              <!-- <span> <strong>{{ row.item.qty_after_transaction.toFixed(0) }}</strong></span> -->
            </b-progress-bar>
          </b-progress>
        </b-container>
      </template>

      <template #cell(item_name)="row">
        <span v-if="!filters.item_code">
          {{ row.item.item_name }}
        </span>
      </template>

    </b-table>
    <div>
      <b-pagination
        v-model="currentPage"
        :total-rows="stockItems.length"
        :per-page="perPage"
        variant="primary"
        aria-controls="my-table"
      />
    </div>
  </b-container>
</template>

<script>
import UserWarehouse from '@/views/erp/billing/components/chargesheet/components/UserWarehouse.vue'

export default {
  name: 'CheckStockBalance',
  filters: {
    // Filter definitions
    dateFormat(date) {
      const current_datetime = new Date(date)
      return `${current_datetime.getFullYear()}-${current_datetime.getMonth() + 1}-${current_datetime.getDate()} ${
        current_datetime.getHours()}:${current_datetime.getMinutes()}:${current_datetime.getSeconds()}`
    },
    Upper(value) {
      return value.toUpperCase()
    },
    numberWithCommas(x) {
      if (x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return 0
    },
  },
  components: { UserWarehouse },
  data() {
    return {
      fields1: ['item_name', 'opening_qty', 'opening_val', 'in_qty', 'in_val', 'out_qty', 'out_val', 'bal_qty', 'val_rate', 'bal_val'],

      stockItems: [],
      medicationItemGroups: [],
      perPage: 10,
      currentPage: 1,
      warehouse: null,
      filters: {},
      item_code: null,
    }
  },

  computed: {
    fields() {
      return ['id', 'date', { key: 'item_name', label: 'Item' }, 'out_qty', 'in_qty', { key: 'qty_after_transaction', label: 'Balance' }, { key: 'percent', label: 'Percent' }, { key: 'stock_value', label: 'Value' }, { key: 'valuation_rate', label: 'Rate' }]
    },
  },
  watch: {
    warehouseName() {
      this.clearFilters()
    },
  },
  mounted() {
    this.filters.company = frappe.defaults.get_user_default('Company')
    this.makeItemControl()
    this.makeItemGroupControl()
  },
  methods: {

    runReport(filters) {
      const context = this
      const lastFilter = {
        company: frappe.defaults.get_user_default('Company'),
        from_date: filters.from_date,
        to_date: filters.to_date,
        warehouse: filters.warehouse,
        item_code: filters.item_code,

      }
      this.api({
        method: 'frappe.desk.query_report.run',
        type: 'GET',
        args: {
          report_name: 'Stock Ledger',
          filters: lastFilter,
          user: 'Administrator',
          ignore_prepared_report: 1,

        },
        callback(r) {
          if (r.message.result) {
            context.stockItems = r.message.result.map((item, index) => {
              item._cellVariants = {}

              if (item.out_qty < 0) {
                item._cellVariants.out_qty = 'danger'
              }

              if (item.in_qty > 0) {
                item._cellVariants.in_qty = 'success'
              }
              item.id = r.message.result.length - index
              return item
            }).reverse()
            console.log(context.stockItems)
            console.log(r.message.result)
          }
        },
      })
    },
    checkAvailability(filters) {
      this.runReport(filters)
      // warehouseStockBalance(filters).then(stocks => {
      //     this.stockItems = stocks;
      // });
    },
    setWarehouse(wh) {
      this.warehouse = wh
      this.filters.warehouse = this.warehouse
    },
    makeItemControl() {
      const me = this
      const customer_field = frappe.ui.form.make_control({
        df: {
          label: ('Search for Item'),
          fieldtype: 'Link',
          fieldname: 'item',
          options: 'Item',
          filters: { is_stock_item: 1 },
          placeholder: ('Search for item '),
          onchange() {
            me.filters.item_code = this.value
            me.item_code = this.value
          },
        },
        parent: this.$refs['item-search'],
        render_input: true,
      })
      customer_field.toggle_label(false)
    },
    makeItemGroupControl() {
      const me = this
      const customer_field = frappe.ui.form.make_control({
        df: {
          label: ('Search for Item Group'),
          fieldtype: 'Link',
          fieldname: 'item_group',
          filters: { is_prescription_item: 1 },
          options: 'Item Group',
          placeholder: ('Search for item group'),
          onchange() {
            me.filters.item_group = this.value
          },
        },
        parent: this.$refs['item-group-search'],
        render_input: true,
      })
      customer_field.toggle_label(false)
    },
    applyFilters() {
      if (!this.filters.from_date || !this.filters.to_date) {
        const date = new Date()
        const firstDay = new Date(date.getFullYear(), date.getMonth(), 1).toISOString().split('T')[0]
        const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0).toISOString().split('T')[0]
        this.filters.from_date = firstDay
        this.filters.to_date = lastDay
        this.msgprint('From Date and To Date Not Set, System picked current Month')
      }
      this.checkAvailability(this.filters)
    },
    clearFilters() {
      const div = document.querySelector('#item');
      [].slice.call(div.children).forEach(child => div.removeChild(child))
      const div2 = document.querySelector('#item-group');
      [].slice.call(div2.children).forEach(child => div2.removeChild(child))
      this.makeItemControl()
      this.makeItemGroupControl()
      this.stockItems = []
      this.filters.item_code = null
      this.filters.item_group = null
    },
  },
}
</script>
<style>
.filter-button {
    height: 35px;
}

.awesomplete>ul {
    z-index: 10000 !important;
}</style>
