<template>
  <div>
    <b-button
      style="margin-right:2px;"
      variant="primary"
      @click="openAddPrescriptionModal"
    >
      Add Prescription
    </b-button>

    <b-modal
      v-model="addPrescriptionModalVisible"
      title="Add a Prescription"
    >
      <b-form @submit.prevent="submitPrescription">
        <b-form-group
          label="Patient"
          label-for="patient"
        >
          <doctype-link
            v-model="patient"
            :doctype="'Patient'"
            required
          />
        </b-form-group>

        <b-table
          :items="drug_list"
          :fields="fields"
          selectable
          select-mode="multi"
        >
          <!-- Link field templates -->
          <template v-slot:cell(drug_code)="data">
            <doctype-link
              v-model="data.item.drug_code"
              :doctype="'Item'"
              :filters="{ is_stock_item: 1, disabled: 0, has_variants: 'No', item_group: ['IN', medicationItemGroups] }"
              required
            />
          </template>
          <template v-slot:cell(dosage)="data">
            <doctype-link
              v-model="data.item.dosage"
              :doctype="'Prescription Dosage'"
              required
            />
          </template>
          <template v-slot:cell(period)="data">
            <doctype-link
              v-model="data.item.period"
              :doctype="'Prescription Duration'"
              required
            />
          </template>
          <template v-slot:cell(dosage_form)="data">
            <doctype-link
              v-model="data.item.dosage_form"
              :doctype="'Dosage Form'"
              required
            />
          </template>
        </b-table>

        <b-button
          type="submit"
          variant="primary"
        >
          Submit
        </b-button>
        <b-button
          variant="secondary"
          @click="cancelAddPrescription"
        >
          Cancel
        </b-button>
      </b-form>
    </b-modal>
  </div>
</template>

<script>

export default {
  data() {
    return {
      addPrescriptionModalVisible: false,
      patient: null,
      medicationItemGroups: [],
      drug_list: [],
      fields: [
        { key: 'start_date', label: 'Start Date', sortable: true },
        { key: 'drug_code', label: 'Drug', sortable: true },
        { key: 'dosage', label: 'Dosage', sortable: true },
        { key: 'period', label: 'Period', sortable: true },
        { key: 'dosage_form', label: 'Dosage Form', sortable: true },
        { key: 'comment', label: 'Comments/Instructions', sortable: true },
      ],
    }
  },
  methods: {
    openAddPrescriptionModal() {
      // Set the initial values for the modal
      this.patient = null
      this.drug_list = []
      this.addPrescriptionModalVisible = true
      this.fetchItemGroups()
    },
    cancelAddPrescription() {
      // Hide the modal on cancel
      this.addPrescriptionModalVisible = false
    },
    submitPrescription() {
      // Perform your submit logic for creating a prescription here
      const values = {
        patient: this.patient,
        drug_list: this.drug_list,
      }

      // Example of calling your API function
      // Replace with actual API call
      createPatientEncounter(values.patient, this.walkin)
        .then(val => {
          createPrescription({ ...values, patient_name: values.patient, encounter: val.name }, values.drug_list, 'Multiple')
            .then(encounter => {
              this.addPrescriptionModalVisible = false
              this.msgprint({
                message: 'Prescription Saved',
                indicator: 'green',
              }, 5)
            })
        })
        .catch(error => {
          // Handle the error scenario
          console.error('Error creating prescription:', error)
          // Add appropriate error handling or show an alert
        })
    },
    fetchItemGroups() {
      // Replace with actual API call to fetch medication item groups
      // Update this.medicationItemGroups with the response
    },
  },
}
</script>
