<template>
  <b-list-group-item
    class="d-flex align-items-center"
    :class="{ 'no-radius': fullScreenWorkArea }"
    :variant="activeStatus"
    @click="clickPatient"
  >
    <b-avatar
      v-if="!fullScreenWorkArea && patient.visit_initiated == 1 && patient.status != 'Admitted'"
      class="mr-2"
      style="color:black;border:2px solid #a6d496"
      :text="patient.order"
      :size="avatarSize"
    />
    <b-avatar
      v-else
      class="mr-2"
      :text="patient.order"
      :size="avatarSize"
    />

    <b-row
      v-if="fullScreenWorkArea"
      class="space-between"
    >
      <b-col class="time-text">
        {{ patient | subtitle }}
      </b-col>
    </b-row>

    <b-row v-else>
      <div>
        <div class="title-name">{{ patient.patient_name }} <b-badge
          style="border:1px solid gray"
          pill
          variant="secondary"
        >{{ patient.age + "(" + patient.gender.charAt(0) + ")" }}</b-badge></div>
      </div>

      <b-col cols="2">
        <span>
          <b-avatar
            v-if="false"
            class="mr-3"
            style="color:black;border:2px solid #0EBFE9"
            :text="patient.static_queue_number || 0"
            :size="avatarSize"
          />
        </span>
      </b-col>
      <b-col
        cols="12"
        class="time-text"
      >{{ patient.bed || patient.appointment_time || patient.patient }}
      </b-col>
      <b-col
        cols="12"
        class="time-text"
      > <div v-if="patient.inpatient_occupancies[patient.inpatient_occupancies.length - 1].medications_billed ===1">
          <b-badge
            pill
            variant="success"
          >
            Done
          </b-badge>
        </div>
        <div v-if="patient.inpatient_occupancies[patient.inpatient_occupancies.length - 1].medications_billed ===0">
          <b-badge
            pill
            variant="warning"
          >
            Pending
          </b-badge>
        </div>
      
      </b-col>
    </b-row>
  </b-list-group-item>
</template>

<script>


export default {

  props: {
     patient: {
      type: Object,
      default: null,
    },
    status: String,
    time: String,
    callback: Function,
    isActive: {
      type: Boolean,
      default: true,
    },
    fullScreenWorkArea: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
      default: null,
    },
  
  },
  data() {
    return {
      activeStatus: '',
      subtitle: '',
      avatarSize: '29px',
    }
  },
  computed: {
    activePatient() {
      return this.$store.getters['patient/getSelectedPatient']
    },
  },
  watch: {
    activePatient(activePatient) {
      if (activePatient.name == this.patient.name) {
        this.activeStatus = 'secondary'
      } else {
        this.activeStatus = ''
      }
    },
  },
  mounted() {
    if (this.activePatient.name == this.patient.name) {
      this.activeStatus = 'secondary'
    } else {
      this.activeStatus = ''
    }
  },
  methods: {
    clickPatient() {
      this.$emit('patient-selected', this.patient)
    },
    getRandomInt(min, max) {
      min = Math.ceil(min)
      max = Math.floor(max)
      return Math.floor(Math.random() * (max - min + 1)) + min
    },
    bllingVariant() {
      if (this.patient.inpatient_occupancies[this.patient.inpatient_occupancies.length - 1].medications_billed === 1) {
        return 'success'
      }
      return 'warning'
    },
    clickPatient() {
      this.$emit('patient-selected', this.patient)
    },
  },
}
</script>

<style>
.time-text {
  font-size: smaller;
  font-weight: bolder;
}

.title-name {
  font-size: small;
}

.space-between {
  display: flex;
  flex: 1;
  justify-content: space-between;
}

.no-radius {
  border-radius: 0px !important;
}

/* .badge-secondary {
  color: #fff;
  background-color: #0a0f0f3b;
} */

.list-group-item {
  position: relative;
  display: block;
  padding: 8px 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:hover {
  background: #1f19192b;

}

.list-group-item-secondary {
  /* -webkit-box-shadow: -3px 0px 3px 4px rgba(27, 21, 76, 0.7);
  box-shadow: -3px 0px 3px 4px rgba(27, 21, 76, 0.7); */
  border-radius: 0px;
  border-left: 5px solid #7c2112;
}

.list-group-item-secondary {
  background: #1f19192b;
}

.b-avatar {
  font-size: inherit;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  background: white;
  color: black;
  border: 1px solid darkgray;
}
</style>
