<template>
  <div>
    <b-row class="w-100 mb-1" align-h="between">
      <h5 v-if="patient && patient.name"><span style="text-transform: capitalize;">{{ patient.patient_name }} - {{
        patient.patient }}
        {{ patient.age }} - {{ patient.gender }}
        [{{ patient.bed }}]
      </span></h5>
      <b-button-group v-if="patient && patient.name">
          <b-button
            variant="dark"
            size="sm"
            @click="markAsBilled"
          >
            Mark As Billed
          </b-button>
          <!-- <b-button variant="warning">Warning</b-button> -->
        </b-button-group>
    </b-row>

   

    <b-row class="w-100">
      <b-table
        stacked="md"
        small
        hover
        :fields="fields"
        :items="todayMedications"
        bordered
        striped
        :empty-text="`No Medication items for Today `"
        :empty-filtered-text="`No Medication items for Today`"
        head-row-variant="primary"
        :show-empty="true"
      >
        <!--  <template #cell(action)="row">
                        <b-btn variant="primary">Bill</b-btn>
                    </template>  -->
      </b-table>
    </b-row>
    <!-- <b-modal hide-footer ref="my-modal" title="Create Charge Sheet">
            <ChargeSheetModal  :patient="patient.patient" :inpatientRecord="patient.name" @chargeSheetCreated="chargeSheetCreated"></ChargeSheetModal>
        </b-modal> -->
    <b-row v-if="patient && patient.name">
      <b-col>
        <ChargeSheet
          :inpatient-medications="todayMedications"
          :patient="patient.patient"
          @billingComplete="markAsBilled"
        />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import ChargeSheet from '@/views/erp/billing/components/chargesheet/ChargeSheet.vue'
import { getCurrentDateMedicationOrders, markOccupancyMedicationsBilled } from './service'
import ChargeSheetModal from './ChargeSheetModal.vue'

export default {
  name: 'MedicationList',
  components: {
    ChargeSheetModal,
    ChargeSheet,
  },
  props: {
    selectedPharmacy: {
      type: String,
      default: '',
      required: true,
    },
    patient: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      testData: 'Patient',
      todayMedications: [],
      fields: ['drug_name', 'dosage', 'date', 'time', 'instructions'], // action
      chargeSheetName: null,

    }
  },
  watch: {
    patient() {
      // alert('patient changed to ' + this.patient.patient_name)
      this.getMedications()
    },
  },
  mounted() {
    //   this.makeFields();
  },
  methods: {
    markAsBilled() {
      // alert(this.patient.inpatient_occupancies[this.patient.inpatient_occupancies.length - 1].name)
      const { name } = this.patient.inpatient_occupancies[this.patient.inpatient_occupancies.length - 1]
      markOccupancyMedicationsBilled({ occupancy_name: name }).then(data => {
        this.msgprint({
          message: 'Success',
          indicator: 'green',
        }, 5)
      })
    },
    chargeSheetCreated(data) {
      // alert(JSON.stringify(data));
    },
    createChargeSheet() {
      const chargeSheet = {
        patient: this.patient,
        encounter: '',
        warehouse: this.selectedPharmacy,
        is_inpatient_bill: 1,
        customer: this.selected_payment_mode_customer,
        is_private_wing: this.wing == this.Private_Wing_String ? 1 : 0,
        doctype: 'Charge Sheet',
        needs_approval: 0,
      }
      const parent = this
      api_call({
        method:
                    'billing.billing.api.charge_sheet.actions.create_charge_sheet.create_charge_sheet_sql',
        args: {
          payload: chargeSheet,
        },
        freeze: false,
      }).then(r => {
        console.log(r)
        parent.response_message = r.success.message
        parent.makeVarToast('success')

        parent.chargeSheetName = r.success.charge_sheet_name
        parent.section = r.success.bill_type

        parent.chargesheettotal = r
        // refresh div
        parent.refreshKey += 1
        console.log(parent.chargesheettotal)
      })
    },
    showCreateDialog() {
      // this.get_selected_payment_mode_options();
      this.$refs['my-modal'].show()
    },
    getMedications() {
      getCurrentDateMedicationOrders({ patient: this.patient.patient }).then(data => {
        // console.log(data);
        this.todayMedications = data || []
      })
    },
  },
}
</script>
