import {
  getPatientPrescriptionsV2,
}
  from '../../pharmacy/working-area/components/prescriptions/service'

export default {
  fetchPrescription({ commit }, payload) {
    getPatientPrescriptionsV2({
      patient_name: payload.patient,
      encounter_name: payload.encounter,
      ...payload,
    }).then(result => {
      if (result) {
        // alert('SET_PRESCRIPTION')
        // console.log(JSON.stringify(result))
        // SET_PRESCRIPTION
        commit('SET_PRESCRIPTIONS', result)
      }
    })
  },

}
