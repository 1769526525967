<template>
  <div>
    <b-row>
      <b-alert
        variant="danger"
        show
      >*NOTICE* This is a list of Staged Drugs, To Dispense Submit Charge Sheet in the
        CHARGE SHEETS Tab.</b-alert>
    </b-row>
    <h5 v-if="unpaidItems.length">
      Prescriptions
    </h5>
    <b-table
      v-if="unpaidItems.length"
      :fields="unpaidFields"
      :items="unpaidItems"
      bordered
      striped
      :empty-text="`No items `"
      :empty-filtered-text="`No items`"
      head-row-variant="primary"
      :show-empty="true"
    >

      <template #cell(id)="row">
        {{ row.index + 1 }}
      </template>
      <template #cell(actions)="row">
        <b-button
          variant="danger"
          @click="onCancel(row.item)"
        >
          Cancel
        </b-button>
      </template>
    </b-table>

    <b-row
      class="w-100 mt-1"
      align-h="between"
    >
      <h6>STAGED</h6>
      <h6> UNPAID: KES {{ totalUnpaid }} </h6>
    </b-row>
    <b-table
      small
      :fields="fields"
      :items="drugs"
      bordered
      striped
      :empty-text="`No items `"
      :empty-filtered-text="`No items`"
      head-row-variant="primary"
      :show-empty="true"
    >

      <template #cell(id)="row">
        {{ row.index + 1 }}
      </template>
      <template #cell(actions)="row">
        <b-button
          style="margin-left: 8px"
          variant="danger"
          @click="cancelToDispense(row.item)"
        >
          Cancel
        </b-button>
      </template>
    </b-table>
    <h6>DISPENSED</h6>
    <b-table
      small
      :fields="dispensedFields"
      :items="dispensed"
      bordered
      striped
      :empty-text="`No items `"
      :empty-filtered-text="`No items`"
      head-row-variant="primary"
      :show-empty="true"
    >

      <template #cell(id)="row">
        {{ row.index + 1 }}
      </template>
      <template #cell(actions)="row">
        <b-button
          variant="primary"
          @click="splitPrescription(row.item)"
        >
          Duplicate
        </b-button>
      </template>
    </b-table>
  </div>
</template>
<script>
import {
  getDrugsAwaitingDispense,
  dispenseStockEntry,
  cancelOrderedPrescription,
  cancelOrderReadyForDispense,
  splitPrescriptionsApi,
} from './prescriptions/service'

export default {
  name: 'AwaitingDispense',
  props: {
    selectedPharmacy: {
      type: String,
      default: '',
      required: true,
    },
  },
  props: {
    patientNumber: {
      type: String,
      default: '',
      required: true,
    },
    queueState: {
      type: Object,
      default: {},
      required: true,
    },
  },

  data() {
    return {
      unpaidFields: [
        'id',
        // "drug_name",
        'item_name',
        { key: 'creation', label: 'Date', formatter: (value, key, item) => moment(item.creation).fromNow() },
        // "sales_order",
        { key: 'to_dispense', label: 'QTY' },
        // {
        //   key: "total_amount",
        //   label: "Total Amount",
        //   formatter: (value, key, item) => Number(value).toLocaleString(),
        // },
        // "payment_status",
        'instructions',
        { key: 'actions', label: ' ' },
      ],
      unpaidItems: [],
      fields: [
        'id',
        'drug_name',
        // "item_name",
        { key: 'creation', label: 'Date', formatter: (value, key, item) => moment(item.creation).fromNow() },
        'total_amount',
        { key: 'to_dispense', label: 'QTY' },
        'dosage',
        'dosage_form',
        'instructions',
        { key: 'actions', label: '' },
      ],
      totalUnpaid: 0,
      drugs: [],
      dispensed: [],
      dispensedFields: [
        'id',
        { key: 'creation', label: 'Date', formatter: (value, key, item) => moment(item.creation).fromNow() },
        'drug_name',
        // "item_name",
        { key: 'quantity', label: 'QTY' },
        'dosage_form',
        'instructions',
        { key: 'actions', label: '' },
      ],
    }
  },
  watch: {
    patientNumber() {
      this.mountMethod()
    },
  },
  mounted() {
    this.mountMethod()
  },
  methods: {
    onDispense(item) {
      console.log(item)
      dispenseStockEntry({
        warehouse: item.item.warehouse,
        patient_name: this.patientNumber,
        prescription_item: item.item.name,
        sales_order: item.item.sales_order,
        doctors_prescription: item.item.prescription_name,
      }).then(() => {
        this.msgprint(
          {
            message: 'Stocks Updated',
            indicator: 'green',
          },
          15,
        )
        this.mountMethod()
        removeFromQueue({ queue_state_name: parent.queueState.name }).then(
          r => {
            console.log('REMOVED FROM QUEUE')
          },
        )
      })
    },
    splitPrescription(item) {
      splitPrescriptionsApi({ name: item.prescription_name }).then(() => { })
      item.name = `${item.name}1`
      // this.prescription = [...this.prescription, item]
    },
    cancelToDispense(item) {
      frappe.confirm(
        'Are you sure you want to cancel this order?',
        () => {
          cancelOrderReadyForDispense(item).then(() => {
            this.mountMethod()
          })
        },
        () => {
          // action to perform if No is selected
        },
      )
    },
    onCancel(item) {
      cancelOrderedPrescription(item).then(() => {
        this.mountMethod()
      })
    },
    mountMethod() {
      getDrugsAwaitingDispense({ patient: this.patientNumber }).then(
        drugs => {
          this.drugs = drugs.filter(e => e.payment_status === 'Completed' || e.payment_status === 'Pending Supplies Approval')
          this.unpaidItems = drugs.filter(
            e => e.payment_status !== 'Completed' && e.payment_status !== 'Pending Supplies Approval',
          )
          console.table(this.unpaidItems)
          this.totalUnpaid = Math.ceil(
            this.unpaidItems.reduce((a, b) => a + parseFloat(b.total_amount), 0),
          )
        },
      )
      getDrugsAwaitingDispense({
        patient: this.patientNumber,
        status: 'Dispensed',
      }).then(drugs => {
        this.dispensed = drugs
      })
      console.table(this.dispensed)
    },
  },
}
</script>
