<template>
  <div
    class=""
    style="margin-left: -1%;"
  >
    <b-modal
      id="modal-prevent-closing"
      ref="changeServiceUnitModal"
      title="Select Pharmacy Warehouse"
      @hidden="resetServiceUnits"
      @ok="updateWarehouse"
    >
      <b-form-select
        v-model="warehouse"
        :options="warehouses"
        placeholder="Select warehouse"
      />
      <div class="mt-3">
        Selected: <strong>{{ warehouse }}</strong>
      </div>
    </b-modal>
    <!-- <div v-if="loading" style="padding-top:25%; text-align:center; height:100vh;">
                                                                                <b-spinner style="width: 300px; height: 300px;margin:auto;"></b-spinner>
                                                                            </div> -->

    <div style="margin-top: 0px">
      <b-row>
        <b-col>
          <div style="display: flex; justify-content: flex-end">
            <b-button-group style="margin-right: 16px; margin-top: 4px">
              <!-- <b-button style="margin-right: 2px" variant="primary" @click="postInvoice">Create Sales Order</b-button> -->
              <!-- <b-button
                v-if="prescription && prescription.length"
                style="margin-right: 2px"
                variant="primary"
                @click="printPrescription(prescription[0])"
                >Print</b-button
              > -->
              <!-- <b-button style="margin-right:2px;" variant="primary" @click="checkInvoice">Payment </b-button> -->
              <b-button
                v-if="paidPres.length > 0"
                style="margin-right: 2px"
                variant="primary"
                @click="dispense"
              >Dispense</b-button>
              <!-- <b-button style="margin-right:2px;" variant="primary" @click="transferPrescription">Transfer</b-button> -->
            </b-button-group>
          </div>
        </b-col>
      </b-row>
      <b-row class="mx-2">
        <div>
          <h4>
            {{ showPrevious ? 'All Prescriptions' : `Today's Prescriptions` }}
          </h4>
        </div>
        <b-col />
        <b-form-checkbox
          v-model="showPrevious"
          switch
        >Click to {{ showPrevious ? 'Show Today only' : `Show Previous
                  Prescriptions` }}</b-form-checkbox>
      </b-row>

      <div class="table-responsive">

        <b-table
          :fields="fields"
          class="table"
          stacked="md"
          small
          hover
          :items="showPrevious ? prescription : filterdPrescription"
          bordered
          striped
          :empty-text="`No items `"
          :empty-filtered-text="`No items`"
          head-row-variant="primary"
          :show-empty="true"
        >
          <template #cell(id)="row">
            {{ row.index + 1 }}
          </template>
          <template #cell(action)="row">

            <b-dropdown
              id="dropdown-left"
              size="sm"
              text="ACTIONS "
              variant="primary"
            >
              <b-dropdown-item @click="openModal(row.item)">
                View
              </b-dropdown-item>
              <b-dropdown-item @click="openEditModal(row.item)">
                Edit Prescription
              </b-dropdown-item>
              <b-dropdown-item @click="printPrescription(row.item)">
                Print Prescription
              </b-dropdown-item>
              <b-dropdown-item @click="downloadPDFNew(row.item)">
                Print Prescription 2
              </b-dropdown-item>
              <b-dropdown-item @click=" downloadPDF(row.item)">
                Download
              </b-dropdown-item>
              <b-dropdown-item @click="splitPrescription(row.item)">
                Duplicate Item
              </b-dropdown-item>
            </b-dropdown>
            <div
              v-if="false"
              style="display: flex;"
            >
              <b-button
                class="sm"
                variant="primary"
                @click="openModal(row.item)"
              >
                View
              </b-button>
              <b-button
                style="margin-left: 8px"
                variant="primary"
                @click="openEditModal(row.item)"
              >Edit
                Prescription</b-button>
              <b-button
                style="margin-left: 8px"
                variant="primary"
                @click="printPrescription(row.item)"
              >
                Print
              </b-button>
              <b-button
                style="margin-left: 8px"
                variant="primary"
                @click="splitPrescription(row.item)"
              >Duplicate</b-button>
            </div>
          </template>
        </b-table>
        <h4
          v-if="staged.length"
          style="margin-top: 16px"
        >
          Drugs Staged to Bill
        </h4>
        <b-table
          v-if="staged.length"
          :items="staged"
          :fields="toDispenseFields"
        >
          <template #cell(id)="row">
            {{ row.index + 1 }}
          </template>
          <template #cell(quantity)="i">
            <b-input
              v-model="i.item.quantity"
              type="number"
              name="quantity"
              step=".01"
              :max="i.item.quantity"
              :value="i.item.quantity"
              @blur="calculateUnpaid(e, i)"
            />
          </template>
          <template #cell(action)="row">
            <b-button
              variant="danger"
              @click="unStage(row)"
            >
              Unstage
            </b-button>
          </template>
        </b-table>
        <div
          v-if="staged.length"
          style="display: flex; justify-content: space-between; padding: 16px; background: lightgray; align-items: center"
        >
          <h4>Total Amount to Bill</h4>
          <strong>
            <h4>KES {{ totalUnpaid.toFixed(e).toLocaleString() }}</h4>
          </strong>
        </div>
        <div style="display: flex; justify-content: space-between; padding: 16px; align-items: center">
          <h4>Insurance Limit</h4>
          <div style="display: flex; align-items: center;">
            <h4 style="margin-right: 32px; margin-bottom: 0">
              KES
            </h4><b-input
              v-model="insurance"
              type="number"
              style="width: 200px"
            />
          </div>
        </div>
        <div style="display: flex; justify-content: space-between; padding: 16px; align-items: center">
          <h4>Individual Amount</h4>
          <div style="display: flex; align-items: center;">
            KES <span>{{ Number(Math.ceil(totalUnpaid - insurance, 0)).toFixed(2).toLocaleString() }}</span>
          </div>
        </div>
        <div
          v-if="staged.length"
          style="display: flex; justify-content: flex-end; padding: 16px; align-items: center"
        >
          <b-button
            variant="primary"
            @click="createSalesOrders()"
          >
            Create Charge Sheet For Staged Items
          </b-button>
        </div>
        <b-modal
          id="modal-1"
          ref="viewPrescription"
          size="lg"
          :ok-title="activeRow.patient_appointment ? 'submit' : 'Create Medication Order'"
          title="Doctor's Drug Prescription"
          @ok="stageForOrder()"
          @hidden="clearSelected()"
        >

          <div style="margin-top: 16px">
            <strong>Patient Status:</strong>
            <span style="margin-bottom: 0">{{ activeRow.inpatient_record ? 'Inpatient' : 'Outpatient' }}</span>
          </div>
          <div style="margin-top: 16px">
            <strong>Drug:</strong>
            <pre style="margin-bottom: 0">{{ activeRow.drug_name }}</pre>
          </div>
          <div style="margin-top: 16px">
            <strong>Dose:</strong>
            <pre style="margin-bottom: 0">{{ activeRow.dose }}</pre>
          </div>
          <div style="margin-top: 16px">
            <strong>Route of Administration:</strong>
            <pre style="margin-bottom: 0">{{ activeRow.route_of_administration }}</pre>
          </div>
          <div style="margin-top: 16px">
            <strong>Start Date:</strong>
            <pre style="margin-bottom: 0">{{ activeRow.start_date }}</pre>
          </div>
          <div style="margin-top: 16px">
            <strong>Instructions:</strong>
            <pre style="margin-bottom: 0">{{ activeRow.instructions }}</pre>
          </div>
          <div style="margin-top: 16px">
            <h4>Matched Stock Items</h4>
            <b-table
              bordered
              :items="items"
              :fields="['id',
                        { label: 'Select', key: 'select' },
                        { label: 'Item Name', key: 'item_name', },
                        { label: 'Quantity', key: 'quantity' },
                        'in_stock',
                        { label: 'Rate', key: 'rate' },
                        { key: 'total', label: 'Total' },
              ]"
            >
              <template #cell(id)="row">
                {{ row.index + 1 }}
              </template>
              <template #cell(in_stock)="row">
                <span v-if="row.item.stock_balance.length > 0 "> {{ row.item.stock_balance[0] }}</span>
                <span v-else>0</span>
              </template>
              <template #cell(rate)="i">
                <div style="text-align: right;">
                  {{ i.item.rate.toFixed(2) }}
                </div>
              </template>
              <template #cell(total)="item">
                <div style="text-align: right;">
                  <span>{{ 'KES ' + Number(item.item.quantity *
                    parseFloat(item.item.rate)).toFixed(2).toLocaleString() }}</span>
                </div>
              </template>
              <template #cell(item_name)="it">
                <!-- v-if="it.item.rate < 0" -->
                <!-- <span  style="color: red">{{ it.item.item_name }}</span> -->
                <span>{{ it.item.item_name }}</span>
              </template>
              <template #cell(select)="row">
                <!-- <input :disabled="row.item.rate == 0" v-model="selected"
                  @change="onSelect(row.item.item.split(/\s+/).join('-'))" type="radio" variant="primary"
                  name="some-radio" :value="row" /> -->
                <input
                  v-model="selected"
                  type="radio"
                  variant="primary"
                  name="some-radio"
                  :value="row"
                  @change="onSelect(row.item.item.split(/\s+/).join('-'))"
                >
              </template>
              <template #cell(quantity)="i">
                <b-input
                  v-if="selected.item && selected.item.item === i.item.item"
                  :id="i.item.item.split(/\s+/).join('-')"
                  v-model="i.item.quantity"
                  step=".01"
                  :disabled="i.item.rate < 0"
                  min="0.01"
                  type="number"
                  name="qty"
                  :value="i.item.quantity"
                />
                <span v-if="!(selected.item && selected.item.item === i.item.item)">{{ i.item.quantity }}</span>
              </template>
              <!-- <template #cell(action)="row">
                <b-button @click="removeMatchedItem(row.item)" variant="danger">Remove</b-button>
              </template> -->
            </b-table>
            <span style="color: red">* Items in red cannot be selected. (Not in any warehouse)</span>
          </div>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import prescriptionStockModule from '@/views/erp/state/pharmacy-stock/index.js'
import moment from 'moment'
import { get_item_dashboard_data } from '../util/utils'
import Paginator from '../util/Paginator.vue'
import {
  updatePrescriptionItem,
  getPrescriptionInvoice,
  setPrescriptionInvoiceNumber,
  getDrugItemAlternates,
  updatePrescriptionItemDrug,
  getWarehouses,
  updatePrescriptionWarehouse,
  dispensePrescription,
  dispenseStockEntry,
  prescriptionChangeComment,
  stageItemForOrder,
  createDrugSalesOrder,
  getMatchedItems,
  matchStockItemApi,
  calculatePrescriptionQuantity,
  getDrugsAwaitingDispense,
  getDrugGroups,
  updateDrugPrescription,
  removeMatchedItem as removeMatchedItemApi, unStageApi, splitPrescriptionsApi,
  updateQueueStatus, removeFromQueue,
} from './service'

export default {
  components: {
    Paginator,
    // PatientInfo
  },
  filters: {
    // Filter definitions
    Upper(value) {
      return value.toUpperCase()
    },
    title(str) {
      return str.replace(/\w([^-\s]*)/g, txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase())
    },
    numberWithCommas(x) {
      if (x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return 0
    },
  },
  props: {
    queueState: {
      type: Object,
      default: {},
      required: true,
    },
    prescription: {
      type: Object,
      required: true,
    },
    patientData: {
      type: Object,
    },
    patientName: {
      type: String,
      required: true,
      default: '',
    },
    selectedPharmacy: {
      type: String,
      default: '',
      required: true,
    },
  },
  data() {
    return {
      // prescription: {},
      toDispenseFields: ['id', 'drug_name', { key: 'item_name', label: 'Selected Item' }, { key: 'creation', label: 'Creation', formatter: (value, key, item) => moment(item.creation).fromNow() }, 'rate', { label: 'Quantity', key: 'quantity' }, { key: 'total_price', label: 'Total Price', formatter: (value, key, item) => item.quantity * parseFloat(item.rate) }, { key: 'action', label: ' ' }],
      staged: [],
      itemGroups: [],
      totalUnpaid: 0,
      selected: {},
      filterdPrescription: [],
      tt: false,
      prescriptionItems: [],
      prescriptionItemsPage: [],
      loading: true,
      alternativeDrugs: [],
      alternativeDrug: null,
      prescriptionItem: null,
      warehouses: [],
      warehouse: null,
      customerInfo: null,
      totalAmount: 0,
      selectedPrescriptions: [],
      selectedTotals: 0,
      paidPres: [],
      showPrevious: 0,
      fields: [
        'id',
        'drug_name',
        { label: 'Dose', key: 'doctor_dose' },
        'frequency',
        { key: 'creation', label: 'Creation', formatter: (value, key, item) => moment(item.creation).fromNow() },
        'instructions',
        // { label: 'Ordered By', key: 'practitioner' },
        { label: 'Actions', key: 'action' },
      ],
      activeRow: {},
      items: [],
      addItem: false,
      insurance: 100000,
    }
  },
  watch: {
    patientData() {
      if (this.patientData.customer_group === 'All Customer Groups') {
        this.insurance = 0
      } else {
        this.insurance = 100000
      }
    },
    prescription() {
      (this.selectedPrescriptions = []), (this.selectedTotals = 0)
      // this.totalAmount = Math.ceil(this.prescription.reduce((accum, item) => accum + (item.rate * item.qty || 1), 0)).toFixed(2);
      console.table(this.prescription)
      this.paidPres = this.prescription.filter(e => e.sales_order_status === 'Billing Completed')
      this.filterdPrescription = this.prescription.filter(this.filter)
    },
    activeRow() {
      const promises = [calculatePrescriptionQuantity({ period: this.activeRow.duration, dosage: this.activeRow.frequency }), getMatchedItems({
        drug: this.activeRow.drug, strength: this.activeRow.strength, warehouse: this.selectedPharmacy.warehouse, patient: this.activeRow.patient_number, filters: { item: ['!=', ''] },
      })]
      Promise.all(promises).then(([quantity, message]) => {
        this.items = message.map(item => ({
          quantity: quantity || 1, in_stock: item.stock_balance, rate: 0, ...item,
        }))
      })
    },
    patientName() {
      getDrugsAwaitingDispense({ patient: this.patientName, status: 'Staged' })
        .then(data => {
          this.staged = data.map(e => ({ ...e, initialQuantity: e.quantity, original_qty: e.quantity }))
          this.totalUnpaid = data.reduce((a, b) => a + parseFloat(b.total_price), 0)
        })
    },
  },
  created() {
    this.$store.registerModule('prescriptionStockModule', prescriptionStockModule)
  },
  mounted() {
    // const host = this.get_host(3000);
    // const socket = io.connect();
    // this.socket = socket;
    // let parent = this;
    // socket.on('connect', () => {
    //   console.log('User Prescription connected');
    // });
    this.getStagedItems()
    getDrugGroups().then(data => {
      this.itemGroups = data
    })
  },
  methods: {
    downloadPDF(prescriptionItem) {
      const path = `/api/method/frappe.utils.print_format.download_pdf?doctype=${encodeURI('Patient Encounter')}&name=${encodeURI(prescriptionItem.patient_encounter)}&format=${encodeURI('Encounter Prescription Print Format')}`
      const serverPath = `${location.protocol}//${location.host}${path}`
      console.log(serverPath)
      window.open(serverPath, '_blank').focus()
    },
    downloadPDFNew(prescriptionItem) {
      const path = `/api/method/clinical.api.orders.prescriptions.generate_prescription_pdf?encounter_name=${encodeURI(prescriptionItem.patient_encounter)}`
      const serverPath = `${location.protocol}//${location.host}${path}`
      console.log(serverPath)
      window.open(serverPath, '_blank').focus()
    },
    filter(item) { return !moment(item.creation).diff(moment(), 'days') },
    onSelect(name) {
      // const name = ev.item.split(/\s+/).join('-')
      const el = document.getElementById(name)
      console.log(name, el)
      if (el) {
        el.focus()
      }
    },
    unStage(row) {
      unStageApi(row.item)
        .then(() => {
          this.$emit('onUpdateList')
          getDrugsAwaitingDispense({ patient: this.patientName, status: 'Staged' })
            .then(data => {
              this.staged = data.map(e => ({ ...e, initialQuantity: e.quantity, original_qty: e.quantity }))
              this.totalUnpaid = data.reduce((a, b) => a + parseFloat(b.total_price), 0)
            })
        })
    },
    clearSelected() {
      this.selected = {}
    },
    openEditModal(item) {
      const parent = this
      const previousName = item.drug_name
      const d = new frappe.ui.Dialog({
        title: 'Edit Drug Prescription',
        fields: [{
          label: 'Drug',
          fieldname: 'drug',
          fieldtype: 'Link',
          options: 'Item',
          reqd: true,
          filters: { item_group: ['in', parent.itemGroups], has_variants: 1 },
          default: item.drug,
          onchange: () => {
            this.docGetValue('Item', d.fields_dict.drug.value, 'item_name').then(({ message }) => {
              d.set_value('drug_name', message.item_name)
              d.set_value('instructions', `Take ${message.item_name} ${item.doctor_dose || item.dose || ''} for ${item.duration} ${item.period || ''}`)
              d.refresh()
            })
          },
        }, {
          label: 'Drug Name',
          fieldname: 'drug_name',
          fetch_from: 'drug.item_name',
          fieldtype: 'Data',
          reqd: true,
          default: item.drug_name,
          read_only: 1,
        }, {
          label: 'Instructions',
          fieldname: 'instructions',
          fieldtype: 'Small Text',
          default: item.instructions,
        }],
        primary_action: values => {
          updateDrugPrescription({ ...values, name: item.name })
            .then(res => {
              item.drug_name = res.drug_name
              item.instructions = res.instructions
              item.drug = res.drug
              this.$emit('onUpdateList')
            })
          d.hide()
        },
      })
      d.show()
    },
    removeMatchedItem(item) {
      removeMatchedItemApi(item)
        .then(() => {
          const promises = [calculatePrescriptionQuantity({ period: this.activeRow.duration, dosage: this.activeRow.frequency }), getMatchedItems({
            drug: this.activeRow.drug, strength: this.activeRow.strength, patient: this.activeRow.patient_number, filters: { item: ['!=', ''] },
          })]
          Promise.all(promises).then(([quantity, message]) => {
            this.items = message.map(item => ({
              quantity: 1, in_stock: item.stock_balance, rate: 0, ...item,
            }))
          })
        })
    },
    calculateUnpaid(e, i) {
      const data = this.staged
      const maximum = Math.min(parseInt(i.item.initialQuantity), i.item.quantity)
      i.item.quantity = maximum
      this.totalUnpaid = data.reduce((a, b) => a + (parseFloat(b.rate) * parseFloat(b.quantity)), 0)
    },
    openModal(row) {
      this.activeRow = row
      console.log(row)
      this.$refs.viewPrescription.show()
    },
    addAMatch(item) {
      const d = new frappe.ui.Dialog({
        title: 'Add Matching Stock Item',
        fields: [{
          label: 'Item',
          fieldname: 'item',
          fieldtype: 'Link',
          options: 'Item',
          filters: { has_variants: 0, item_group: ['in', this.itemGroups] },
        },
        {
          label: 'Strength',
          fieldname: 'strength',
          fieldtype: 'Data',
        }, {
          label: 'Route of Administration',
          fieldname: 'dosage_form',
          fieldtype: 'Link',
          options: 'Dosage Form',
        }],
        primary_action: values => {
          matchStockItemApi({ dosage_form: item.dosage_form, ...values, drug: item.drug }).then(() => {
            this.msgprint(`${item.drug} matched successfully with ${values.item}`, 5)
            this.getStagedItems()
            const promises = [calculatePrescriptionQuantity({ period: this.activeRow.duration, dosage: this.activeRow.frequency }), getMatchedItems({
              drug: this.activeRow.drug, strength: this.activeRow.strength, patient: this.activeRow.patient_number, filters: { item: ['!=', ''] },
            })]
            Promise.all(promises).then(([quantity, message]) => {
              this.items = message.map(item => ({
                quantity: 1, in_stock: item.stock_balance, rate: 0, ...item,
              }))
            })
            d.hide()
          })
        },
      })
      d.show()
    },
    stageForOrder() {
      if (!this.selected.item) {
        return frappe.throw('Please select an item or add a matched Item before creating a medication order')
      }

      console.log(this.selected)
      const args = {
        patient: this.activeRow.patient_number,
        encounter: this.activeRow.patient_encounter,
        inpatient_record: this.activeRow.inpatient_record,
        item: this.selected.item.item,
        quantity: this.selected.item.quantity,
        service_unit: this.activeRow.service_unit,
        appointment_name: this.activeRow.patient_appointment,
        doctors_prescription: this.activeRow.name,
        route_of_administration: this.activeRow.route_of_administration || '',
        rate: this.selected.item.rate,

      }

      stageItemForOrder(args).then(() => {
        this.$emit('onUpdateList')
        this.getStagedItems()
      })
    },
    createSalesOrders() {
      const args = {
        items: this.staged,
        limit: this.insurance,
        total: this.totalUnpaid,
        warehouse: this.selectedPharmacy.warehouse,
      }
      createDrugSalesOrder(args).then(() => {
        updateQueueStatus({ state_name: this.queueState.name, status: 'Invoiced' }).then(m => { })
        this.getStagedItems()
        this.$emit('onUpdateList')
      })
    },
    getAmountAndPayerSum(payer, rate, qty, remaining_quantity = 0, co_paid_quantity = 0, type) {
      switch (payer) {
        case 'Patient':
          return type === 'Amount' ? Math.ceil(rate * qty).toFixed(2) || 1 | numberWithCommas : 'Patient incurs'
        case 'Copay':
          return type === 'Amount'
            ? Math.ceil(rate * (remaining_quantity * qty)).toFixed(2) || 1 | numberWithCommas
            : `${'Copaid amount: '
            + '('}${
              Math.ceil(rate * (co_paid_quantity * qty)).toFixed(2) || 1 | numberWithCommas
            })`
        case 'Insurance':
          return type === 'Amount'
            ? 0
            : `${'Insurance covers: ' + '('}${Math.ceil(rate * qty).toFixed(2) || 1 | numberWithCommas})`
        case 'Pre-auth Insurance':
          return type === 'Amount'
            ? 0
            : `${'Insurance Pre-auth: ' + '('}${Math.ceil(rate * qty).toFixed(2) || 1 | numberWithCommas})`
        case 'Pre-auth Copay':
          return type === 'Amount'
            ? Math.ceil(rate * (remaining_quantity * qty)).toFixed(2) || 1 | numberWithCommas
            : `${'Pre-auth Copay:: '
            + '('}${
              Math.ceil(rate * (co_paid_quantity * qty)).toFixed(2) || 1 | numberWithCommas
            })`
      }
    },
    selectAll() {
      this.prescription.forEach(item => {
        if (!item.sales_order) {
          this.selectRow(item)
        }
      })
    },
    splitPrescription(item) {
      splitPrescriptionsApi({ name: item.name }).then(name => {
        item.name = name
        this.prescription = [...this.prescription, item]
      })
    },
    printPrescription(prescriptionItem) {
      const w = window.open(
        frappe.urllib.get_full_url(
          `${'/printview?'
          + 'doctype='}${
            encodeURIComponent('Patient Encounter')
          }&name=${
            encodeURIComponent(prescriptionItem.patient_encounter)
          }&format=Encounter Prescription Print Format&_lang=en&trigger_print=1`,
        ),
      )
      if (!w) {
        msgprint(('Please enable pop-ups'))
      }
    },
    dispense() {
      const paidPrescription = this.prescription.filter(e => e.sales_order_status === 'Fully Billed')
      if (paidPrescription.length) {
        dispenseStockEntry({
          warehouse: paidPrescription[0].warehouse,
          encounter_name: paidPrescription[0].encounter,
          // prescription_names: this.prescription.map(drug=>drug.prescription_id) ,
          sales_order: paidPrescription[0].sales_order,
          patient_name: paidPrescription[0].patient_number,
        }).then(result => {
          this.msgprint(
            {
              message: 'Stocks Updated',
              indicator: 'green',
            },
            15,
          )
          dispensePrescription({
            prescription_names: paidPrescription.map(drug => drug.prescription_id),
            patient_name: paidPrescription[0].patient_number,
            warehouse_name: paidPrescription[0].warehouse,
          }).then(data => {
            this.msgprint(
              {
                message: 'Prescription Dispensed',
                indicator: 'green',
              },
              15,
            )
          })
          removeFromQueue({ queue_state_name: parent.queueState.name }).then(r => {
            console.log('REMOVED FROM QUEUE')
          })
          prescriptionChangeComment({
            reference_doctype: 'Patient Encounter',
            reference_name: paidPrescription[0].encounter,
            content: `Prescriptions : ${paidPrescription.map(x => x.drug_code).toString()} Dispensed`,
            comment_email: this.getUserSession().user,
            comment_by: this.getUserSession().user_fullname,
          }).then(result => { })
        })
      } else {
        this.msgprint(
          {
            message: 'No invoiced Prescription',
            indicator: 'red',
          },
          5,
        )
      }
    },
    selectRow(item) {
      console.log('Start ', this.selectedPrescriptions.length)
      if (typeof item === 'object') {
        let replaced = false
        this.selectedPrescriptions.map((pres, x) => {
          if (pres.prescription_id === item.prescription_id) {
            this.selectedPrescriptions.splice(this.selectedPrescriptions.indexOf(item), 1)
            replaced = true
          }
        })
        if (!replaced) {
          this.selectedPrescriptions.push(item)
        }
        this.selectedTotals = Math.ceil(
          this.selectedPrescriptions.reduce((accum, item) => accum + item.rate * item.qty, 0),
        ).toFixed(2)
      } else {
        //
      }
    },
    printPrescriptionLabel(prescriptionItem) {
      // console.log(JSON.stringify(prescriptionItem))
      // https://docs.erpnext.com/docs/user/manual/en/setting-up/print/raw-printing
      console.log('---------------->', prescriptionItem)
      const w = window.open(
        frappe.urllib.get_full_url(
          `${'/printview?'
          + 'doctype='}${
            encodeURIComponent('Drug Prescription')
          }&name=${
            encodeURIComponent(prescriptionItem.patient_encounter)
          }&format=Drug Prescription Label&_lang=en&no_letterhead=1&letterhead=No Letterhead&trigger_print=1`,
        ),
      )
      if (!w) {
        msgprint(('Please enable pop-ups'))
      }
    },
    createMaterialRequest(prescriptionItem) {
      const parent = this
      const d = new frappe.ui.Dialog({
        title: `Material Request for ${prescriptionItem.drug_name}`,
        fields: [
          {
            label: 'Warehouse',
            fieldname: 'warehouse',
            fieldtype: 'Select',
            options: this.warehouses,
          },
          {
            label: 'Quantity',
            fieldname: 'quantity',
            fieldtype: 'Int',
          },
        ],
        primary_action_label: 'Submit',
        primary_action(values) {
          d.hide()
          parent.$store.dispatch('prescriptionStockModule/createMaterialRequest', {
            drug: prescriptionItem.drug_name,
            quantity: values.quantity,
            warehouse: prescriptionItem.warehouse,
            from_warehouse: values.warehouse,
          })
        },
        secondary_action_label: 'Cancel',
        secondary_action() {
          d.hide()
        },
      })
      d.show()
      this.loading = false
    },
    resetMaterialRequestModal() {
      this.prescriptionItem = null
    },
    materialRequest(prescriptionItem) {
      this.prescriptionItem = prescriptionItem
      getWarehouses({}).then(result => {
        if (result && result.length) {
          this.warehouses = result.map(unit => unit.name)
          this.createMaterialRequest(prescriptionItem)
        } else {
          this.msgprint(
            {
              message: 'No Warehouses Available',
              indicator: 'orange',
            },
            5,
          )
        }
      })
    },
    updateWarehouse() {
      if (this.prescription && this.prescription.name) {
        updatePrescriptionWarehouse({
          prescription_name: this.prescription.name,
          warehouse_name: this.warehouse,
        }).then(result => {
          if (result) {
            this.msgprint(
              {
                message: `Prescription  Moved to ${result.warehouse}.`,
                indicator: 'green',
              },
              5,
            )
            this.prescription = result
            this.loading = false
          } else {
            this.msgprint(
              {
                message: 'Transfer Failed. Please try again',
                indicator: 'red',
              },
              5,
            )
          }
        })
      } else {
        this.msgprint(
          {
            message: 'No Prescription Selected',
            indicator: 'orange',
          },
          5,
        )
      }
      this.loading = false
    },
    resetServiceUnits() {
      this.warehouse = null
    },
    transferPrescription() {
      // frappe.set_route("prescription", this.prescription.name);
      if (this.prescription && this.prescription.name) {
        getWarehouses({}).then(result => {
          if (result && result.length) {
            this.warehouses = result.map(unit => unit.name)
            this.$refs.changeServiceUnitModal.show()
          } else {
            this.msgprint(
              {
                message: 'No Healthcare Service Units',
                indicator: 'orange',
              },
              5,
            )
          }
        })
      } else {
        this.msgprint(
          {
            message: 'No Prescription Selected',
            indicator: 'orange',
          },
          5,
        )
      }
    },
    fetchAlternates(prescriptionItem) {
      this.prescriptionItem = prescriptionItem
      getDrugItemAlternates({
        drug_name: prescriptionItem.drug_code,
      }).then(result => {
        if (result && result.length) {
          this.alternativeDrugs = result.map(drugs => drugs.alternative_item_code)
          this.changeDrug(prescriptionItem, this.alternativeDrugs)
        } else {
          this.msgprint(
            {
              message: `${prescriptionItem.drug_code} has no alternative setup in the system`,
              indicator: 'orange',
            },
            5,
          )
        }
      })
    },
    resetAlternateDrugModal() {
      this.alternativeDrugs = []
      this.alternativeDrug = null
      this.prescriptionItems = null
    },
    changeDrug(prescriptionItem, alternativeDrugs) {
      const parent = this
      // https://frappeframework.com/docs/user/en/guides/app-development/dialogs-types
      const d = new frappe.ui.Dialog({
        title: `Select Alternate Drug for ${prescriptionItem.drug_name}`,
        fields: [
          {
            label: 'Drug',
            fieldname: 'alternativeDrug',
            fieldtype: 'Select',
            options: alternativeDrugs,
          },
        ],
        primary_action_label: 'Submit',
        primary_action(values) {
          updatePrescriptionItemDrug({
            patient_name: prescriptionItem.patient_number,
            prescription_item_name: prescriptionItem.prescription_id,
            drug_name: values.alternativeDrug,
          }).then(result => {
            parent.loading = false
            parent.updateTableRow(result)
          })
          d.hide()
          prescriptionChangeComment({
            reference_doctype: 'Patient Encounter',
            reference_name: prescriptionItem.encounter,
            content: `Prescription Drug Change from ${prescriptionItem.drug_code} to ${values.alternativeDrug}`,
            comment_email: this.getUserSession().user,
            comment_by: this.getUserSession().user_fullname,
          }).then(result => { })
        },
        secondary_action_label: 'Cancel',
        secondary_action() {
          d.hide()
          parent.resetAlternateDrugModal()
        },
      })
      d.show()
      this.loading = false
    },
    updateTableRow(result) {
      this.prescription.map((item, x) => {
        if (item.prescription_id === result.name) {
          Vue.set(this.prescription, x, result)
        }
      })
      this.itemUnitsChanged()
      this.resetAlternateDrugModal()
    },
    numberOfUnitsChange(prescriptionItem) {
      this.loading = true
      updatePrescriptionItem({
        name: prescriptionItem.prescription_id,
        qty: prescriptionItem.qty,
      })
        .then(result => {
          this.loading = false
          this.prescription.prescription_item.map((item, x) => {
            if (item.name === result.name) {
              Vue.set(this.prescription.prescription_item, x, result)
            }
          })
          this.msgprint(
            {
              message: `Drug  #${prescriptionItem.drug_code} unit changed.`,
              indicator: 'green',
            },
            5,
          )
          this.itemUnitsChanged()
        })
        .catch(err => {
          this.loading = false
        })
      this.loading = false
    },
    itemUnitsChanged(type) {
      let totalAmount = 0
      if (this.prescription && this.prescription.length) {
        let itemList = []
        if (type === 'Gross') {
          itemList = this.prescription
        } else {
          itemList = this.selectedPrescriptions
        }
        itemList.forEach((item, index) => {
          switch (item.payer) {
            case 'Patient':
              totalAmount += item.rate * item.qty
              break
            case 'Copay':
              totalAmount += Math.ceil(item.rate * (item.qty * item.remaining_quantity))
              break
            case 'Insurance':
              totalAmount += 0
              break
            case 'Pre-auth Insurance':
              totalAmount += 0
              break
            case 'Pre-auth Copay':
              totalAmount += Math.ceil(item.rate * (item.qty * item.remaining_quantity))
              break
          }
        })
        this.totalAmount = totalAmount
      }

      return totalAmount
    },
    postInvoice() {
      if (this.selectedPrescriptions && this.selectedPrescriptions.length) {
        // console.log(JSON.stringify(this.selectedPrescriptions))
        createPrescriptionSalesOrder({ prescription_docs: this.selectedPrescriptions, warehouse_name: '' }).then(
          r => {
            this.msgprint(
              {
                message: 'Prescription Sales order created',
                indicator: 'green',
              },
              5,
            )
            updateQueueStatus({ state_name: this.queueState.name, status: 'Invoiced' }).then(m => { })
            this.selectedPrescriptions = []
          },
        )
      } else {
        this.msgprint(
          {
            message: 'Please Select prescription',
            indicator: 'orange',
          },
          5,
        )
      }
    },
    getStagedItems() {
      getDrugsAwaitingDispense({ patient: this.patientName, status: 'Staged' })
        .then(data => {
          this.staged = data.map(e => ({ ...e, initialQuantity: e.quantity, original_qty: e.quantity }))
          console.log(data)
          this.totalUnpaid = data.reduce((a, b) => a + parseFloat(b.total_price), 0)
        })
    },
    checkInvoice() {
      if (this.prescription && this.prescription.length && this.prescription[0].sales_order) {
        getPrescriptionInvoice({
          name: this.prescription[0].sales_order,
        }).then(result => {
          this.loading = false
          this.msgprint(
            {
              message: `Prescription Invoice #${result.name} status is ${result.status}`,
              indicator: 'green',
            },
            5,
          )
        })
      } else {
        this.msgprint(
          {
            message: 'Sales Invoice not found.',
            indicator: 'orange',
          },
          5,
        )
      }
      this.loading = false
    },
    setPrescriptionInvoice(invoiceName) {
      setPrescriptionInvoiceNumber({
        prescription_name: this.prescription.name,
        invoice_name: invoiceName,
      }).then(result => {
        if (result) {
          this.msgprint(
            {
              message: `Invoice #${invoiceName} posted. Awaiting Payment`,
              indicator: 'green',
            },
            5,
          )
          this.prescription = result
          this.loading = false
        } else {
          this.msgprint(
            {
              message: 'Invoice not Created',
              indicator: 'red',
            },
            5,
          )
        }
      })
      this.loading = false
    },
    changePage(pageOfItems) {
      this.prescriptionItemsPage = pageOfItems
    },
    stockCheck(prescription) {
      this.api({
        method: 'erpnext.stock.dashboard.item_dashboard.get_data',
        args: {
          item_code: prescription.drug_code,
        },
        callback: ({ message = [] }) => {
          const context = get_item_dashboard_data(message, 10, true)
          if (message.length) {
            let msgText = '<ol>'
            message.forEach(msg => {
              msgText += `<li> ${msg.warehouse}  <b> ${msg.projected_qty}/${msg.actual_qty}</b></li>`
            })
            msgText += '</ol>'
            this.msgprint({
              title: ((prescription.drug_code || '').toUpperCase()),
              indicator: 'green',
              message: msgText,
            })
          } else {
            this.msgprint({
              title: ((prescription.drug_code || '').toUpperCase()),
              message: 'Stock Data Unavailable.',
              indicator: 'green',
            })
          }
          // this.msgprint(JSON.stringify(message))

          // this.msgprint({
          //     title: ((prescription.drug_code || "").toUpperCase()),
          //     indicator: message.length ? "green" : "red",
          //     message: `${
          //     message.length
          //         ? frappe.render_template("item_dashboard_list", context)
          //         : `<div class='text-muted small'>  ${(
          //             "Currently no stock available in any warehouse"
          //         )} </span>`
          //     }`
          // });
        },
      })
    },
    get_host(port = 3000) {
      let host = window.location.origin
      if (window.dev_server || true) {
        const parts = host.split(':')
        port = frappe.boot.socketio_port || port.toString() || '3000'
        if (parts.length > 2) {
          host = `${parts[0]}:${parts[1]}`
        }
        host = `${host}:${port}`
      }
      return host
    },
  },
}
</script>

<style scoped>
.patient-name {
  text-transform: capitalize;
}

.totals {
  font-size: 14px;
  font-weight: 700;
}

.total-row {
  display: flex;
  justify-content: space-between;
}

input[type='number'] {
  padding: 1px;
  border: 1;
  box-shadow: 0 0 15px 4px rgba(0, 0, 0, 0.06);
}

.table-responsive {
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
