import {
  customerInfomation,
} from '../../pharmacy/working-area/components/prescriptions/service'

export default {
  fetchCustomerInfomation({ commit }, patient) {
    customerInfomation({
      patient_name: patient,
    }).then(result => {
      if (result) {
        commit('SET_CUSTOMER_INFO', result)
      }
    })
  },
}
