<template>
  <div class="container">
    <b-row
      alig-h="end"
      class="w-100"
    >
      <b-col />
      <b-button
        size="sm"
        style="margin-right:0px;"
        variant="primary"
        @click="fetchItemGroups"
      >Create Material
        Request</b-button>
    </b-row>

    <b-table
      id="my-table"
      small
      class="mt-1"
      :empty-text="`No items `"
      :empty-filtered-text="`No items`"
      head-row-variant="primary"
      :show-empty="true"
      :per-page="perPage"
      :current-page="currentPage"
      responsive
      :items="materialRequests"
      :fields="fields"
    >
      <template #cell(creation)="data">
        {{ data.item.creation | dateFormat }}
      </template>

      <template #cell(name1)="data">
        <span v-html="data.item.name1" />
        <br> {{ data.item.title }}
      </template>

      <template #cell(stock_entry)="data">
        <span v-html="data.item.stock_entry" />
      </template>

      <template #cell(action)="data">
        <b-btn
          variant="primary"
          @click="viewItems(data.item)"
        >
          View Items
        </b-btn>
      </template>
    </b-table>
    <div>
      <b-pagination
        v-model="currentPage"
        :total-rows="materialRequests.length"
        :per-page="perPage"
        aria-controls="my-table"
      />
    </div>
    <!-- // </b-pagination> -->
  </div>
</template>

<script>
import {
  outgoingMateriallRequests,
  materiallRequestItems,
  api,
  itemStockBalance,
  createMaterialRequest,
} from './service'

export default {
  name: 'OutgoingRequest',
  filters: {
    // Filter definitions
    dateFormat(date) {
      const current_datetime = new Date(date)
      return `${current_datetime.getFullYear()}-${current_datetime.getMonth() + 1}-${current_datetime.getDate()} ${
        current_datetime.getHours()}:${current_datetime.getMinutes()}:${current_datetime.getSeconds()}`
    },
    Upper(value) {
      return value.toUpperCase()
    },
    numberWithCommas(x) {
      if (x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return 0
    },
  },
  props: {
    warehouseName: {
      type: String,
      default: ' ',
      required: true,
    },
  },
  data() {
    return {
      fields: ['creation', { key: 'name1', label: 'Name' }, { key: 'set_from_warehouse', label: 'Source Warehouse' }, { key: 'stock_entry', label: 'Stock Entry' }, 'status', 'action'],
      materialRequests: [],
      medicationItemGroups: [],
      perPage: 10,
      currentPage: 1,
    }
  },
  created() {
    this.getMaterialRequests()
  },
  methods: {
    createMaterialRequest() {
      const parent = this
      const dialog = new frappe.ui.Dialog({
        title: 'Add Material Request',
        fields: [{
          label: 'Drug',
          fieldname: 'drug_code',
          fieldtype: 'Link',
          options: 'Item',
          reqd: true,
          filters: {
            is_stock_item: 1, disabled: 0, has_variants: 'No', item_group: ['IN', parent.medicationItemGroups],
          },
        },
        {
          label: 'Warehouse',
          fieldname: 'warehouse',
          fieldtype: 'Link',
          options: 'Warehouse',
          reqd: true,
        },
        {
          label: 'Quantity',
          fieldname: 'quantity',
          fieldtype: 'Int',
          reqd: 1,
          default: 0,
        },
        ],
        primary_action_label: 'Submit',
        primary_action(values) {
          dialog.hide()
          createMaterialRequest({
            item_code: values.drug_code,
            qty: values.quantity,
            warehouse: parent.warehouseName,
            from_warehouse: values.warehouse,
          }).then(r => {
            parent.getMaterialRequests()
            this.msgprint({
              message: 'material Request Created',
              indicator: 'green',
            }, 5)
          })
        },
        secondary_action_label: 'Cancel',
        secondary_action() {
          dialog.hide()
        },
      })
      const onDrugChange = event => {
        const value = dialog.get_value('drug_code')
        if (value) {
          parent.checkAvailability(value)
        }
      }
      dialog.fields_dict.drug_code.df.onchange = () => {
        const value = dialog.get_value('drug_code')
        if (value) {
          parent.checkAvailability(value)
        }
      }
      dialog.show()
    },
    fetchItemGroups() {
      api({
        method: 'clinical.api.prescription.prescription.get_is_prescription_item',
        args: {
          // doctype: 'Item Group',
          // async: false,
          // filters: { is_prescription_item: 1 }
        },

      }).then(res => {
        this.medicationItemGroups = res
        this.openModal()
      })
    },
    writeUrl(url) { },
    checkAvailability(item) {
      itemStockBalance({ item_code: item }).then(stocks => {
        const bodyElements = document.getElementsByClassName('modal-body')
        const body = bodyElements[bodyElements.length - 1]
        const rptElement = document.getElementById('stock-report-div')
        if (rptElement) {
          rptElement.remove()
        }
        const node = document.createElement('div')
        node.setAttribute('id', 'stock-report-div')
        // node.innerHTML = `Insufficient stocks in the hospital for <strong>${item}</strong>. Consider alternatives listed below or await procurement.`;
        node.innerHTML = `Stock Availability for <b>${item}</b>`
        node.style.color = 'black'

        const ul = document.createElement('ul')
        stocks.forEach(item => {
          const li = document.createElement('li')
          li.innerHTML = `<span><b>${item.warehouse} : ${item.actual_qty} ${item.stock_uom}(s)</b></span>`
          ul.appendChild(li)
        })
        node.appendChild(ul)

        body.appendChild(node)
      })
    },
    viewItems(requestItem) {
      materiallRequestItems({ request_name: requestItem.name }).then(r => {
        console.log(JSON.stringify(r))
        let msgText = '<ul>'
        if (r.length) {
          r.forEach(item => {
            msgText += `<li> ${item.item_code} ${item.qty} ${item.stock_uom} </li>`
          })
          msgText += '</ul>'
          this.msgprint({ title: ((requestItem.name || ' Items').toUpperCase()), indicator: 'green', message: msgText })
        } else {
          this.msgprint({ title: ((requestItem.name || ' Items').toUpperCase()), indicator: 'green', message: 'No items to list' })
        }
      })
    },
    getMaterialRequests() {
      outgoingMateriallRequests({ warehouse_name: this.warehouseName }).then(r => {
        this.materialRequests = r
      })
    },
    getMaterialRequestItems(request) {
      materiallRequestItems({ request_name: request.name }).then(r => {
        materialRequests = r
      })
    },
  },
}
</script>
