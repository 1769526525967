<template>

  <b-list-group-item
    class="d-flex align-items-center"
    style="cursor:pointer;"
    @click="prescriptionClicked"
  >
    <!-- <b-avatar class="mr-3"></b-avatar> -->
    <b-row align-h="between">
      <b-col align-self="stretch"><span class="mr-auto title-name">{{ prescription.patient }}</span>
      </b-col>
      <b-row>
        <b-col
          cols="12"
          class="time-text"
        >
          {{ prescription.status }}
          <span
            v-if="prescription.status === 'Pending'"
            class="indicator whitespace-nowrap orange"
          />
          <span
            v-if="prescription.status === 'Dispensed'"
            class="indicator whitespace-nowrap green"
          />
        </b-col>
        <b-col
          cols="12"
          class="time-text"
        >
          <b-icon-exclamation-triangle-fill />
          {{ prescription.invoice_status }}
          <span
            v-if="prescription.invoice_status === 'Paid'"
            class="indicator whitespace-nowrap green"
          />
          <span
            v-if="prescription.invoice_status === 'Pending' || prescription.invoice_status === 'Invoiced'"
            class="indicator whitespace-nowrap orange"
          />
        </b-col>
      </b-row>
    </b-row>
  </b-list-group-item>
</template>
<script>
export default {
  filters: {
    // Filter definitions
    Upper(value) {
      return value.toUpperCase()
    },
    title(str) {
      return str.replace(/\w([^-\s]*)/g, txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase())
    },
    last_5(str) {
      return str.substring(str.length - 5)
    },
  },
  props: {
    prescription: {
      type: Object,
      default: {},
      required: true,
    },
  },
  methods: {
    prescriptionClicked() {
      // alert(this.prescription);
      this.$emit('prescriptionClicked', this.prescription)
    },
  },
}
</script>
<style scoped>
.title-row {
  display: flex;
  justify-content: space-between;
}
</style>
